<template>
    <v-container>
        <v-row v-if="isAbleToPrint">
            <v-col>
                <PrintTournamentGraphs :aloneTournamenGraphsByCategory="aloneTournamenGraphsByCategory"
                    :tournamenGraphsByCategory="tournamenGraphsByCategory" :graphCompetitionType="graphCompetitionType"
                    :exhibitionTournamenGraphsByCategory="exhibitionTournamenGraphsByCategory"></PrintTournamentGraphs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=12>
                <h2 v-if="$route.params.id">GRAFICA DE TORNEO</h2>
                <v-btn @click="resetGraphs">Reiniciar Gráfica</v-btn>
            </v-col>
            <v-col cols="4">
                <caps-v-text-field v-model="studentSearch" label="Competidor"></caps-v-text-field>
            </v-col>
            <v-col cols=2>
                <h3>Area {{ tableName || 'N/A' }}</h3>
            </v-col>
            <v-col v-if="isGeneralDirection" cols=2>
                <v-checkbox type="checkbox" :label="'Modo editor'" v-model="editorMode"></v-checkbox>
                <input v-if="editorMode" type="checkbox" :label="'Imprimir todas'" @click="printAll"> <span
                    v-if="editorMode">Imprimir todas</span>
            </v-col>
        </v-row>
        <!-- v-tabs for Gráficas, Exhibiciones and Sin competidor -->
        <v-row>
            <v-col cols="12">
                <v-btn v-if="tournament && tournament.has_combat" @click="graphCompetitionType = 1"
                    :class="`category-button ${graphCompetitionType == 1 ? 'blue' : 'green'} lighten-2`">Combate</v-btn>
                <v-btn v-if="tournament && tournament.has_forms" @click="graphCompetitionType = 2"
                    :class="`category-button ${graphCompetitionType == 2 ? 'blue' : 'green'} lighten-2`">Formas</v-btn>
                <v-btn v-if="tournament && tournament.has_techniques" @click="graphCompetitionType = 3"
                    :class="`category-button ${graphCompetitionType == 3 ? 'blue' : 'green'} lighten-2`">Pasos de
                    combate</v-btn>
                <v-btn v-if="tournament && tournament.has_jumping_kicks" @click="graphCompetitionType = 4"
                    :class="`category-button ${graphCompetitionType == 4 ? 'blue' : 'green'} lighten-2`">Idan
                    Chaguis</v-btn>
                <v-btn v-if="tournament && tournament.has_boards" @click="graphCompetitionType = 5"
                    :class="`category-button ${graphCompetitionType == 5 ? 'blue' : 'green'} lighten-2`">Rompimientos</v-btn>
                <v-btn v-if="tournament && tournament.has_kicking_speed" @click="graphCompetitionType = 6"
                    :class="`category-button ${graphCompetitionType == 6 ? 'blue' : 'green'} lighten-2`">Velocidad de
                    pateo</v-btn>
            </v-col>
            <v-tabs v-model="graphTypeSelected">
                <v-tab>Gráficas</v-tab>
                <v-tab>1° LUGAR S/COMPETIR</v-tab>
                <v-tab>Exhibiciones</v-tab>
                <v-tab>Competidores premiados</v-tab>
                <v-tab>Competidores sin premiar</v-tab>
                <v-tab>Premiaciones de doyang</v-tab>
            </v-tabs>
            <v-tabs-items v-model="graphTypeSelected">
                <!--Gráficas-->
                <v-tab-item>
                    <v-row v-for="tournamentGraphInfo in tournamenGraphsByCategory"
                        :key="tournamentGraphInfo['graphs'][0]['tournament_category_id']">
                        <v-col cols="12">
                            <h3>
                                {{ tournamentGraphInfo.name }} ({{ tournamentGraphInfo.graphs.length }})
                                <v-icon @click="toggleCategoryDisplay(tournamentGraphInfo.name)"
                                    class="mouse_over_clickable"
                                    v-if="displayedCategories[tournamentGraphInfo.name]">mdi-chevron-up</v-icon>
                                <v-icon @click="toggleCategoryDisplay(tournamentGraphInfo.name)"
                                    class="mouse_over_clickable" v-else>mdi-chevron-down</v-icon>
                            </h3>
                        </v-col>
                        <v-col v-if="displayedCategories[tournamentGraphInfo.name]">
                            <v-row>
                                <v-col v-for="(graph, index) in tournamentGraphInfo.graphs" :key="graph.graph_number"
                                    cols=12>
                                    <v-card>
                                        <v-card-title v-if="graph">
                                            GRÁFICA: {{ graph['tournament_category_id'] }}.{{ graph['graph_number'] }}
                                            AREA: {{ graph.tournament_category.table_name || 'N/T' }} <v-btn
                                                v-if="editorMode" class="green lighten-2"
                                                @click="changeTable(graph)">Cambiar área</v-btn>
                                            <!--Checkbox para impresión-->
                                            <div class="no-print" v-if="editorMode && shouldGraphBePrinted(graph)">
                                                <v-btn @click="addGraphToPrintIds(graph, true)"
                                                    class="green ligthen-2"><span
                                                        class="no-print">Imprimir</span></v-btn>
                                            </div>
                                            <div class="no-print" v-if="editorMode && !shouldGraphBePrinted(graph)">
                                                <v-btn @click="addGraphToPrintIds(graph, false)"
                                                    class="orange ligthen-2"><span class="no-print">No
                                                        Imprimir</span></v-btn>
                                            </div>
                                            <br>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[4])">
                                                            <span class="no-print"><v-icon
                                                                    v-if="editorMode">mdi-numeric-4-circle</v-icon></span>
                                                            <v-icon
                                                                v-if="graph.users[4] && !(graph.users[4].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[4], graph.users[5], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[4] && graph.users[4].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[4])"
                                                                v-if="graph.users[4] && graph.users[4].placement"
                                                                v-model="graph.users[4].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[4])">{{
            graph.users[4] && graph.users[4].full_name }}
                                                                {{ graph.users[4] && graph.users[4].weight_kg ?
            `(${graph.users[4].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[5])">
                                                            <span class="no-print"><v-icon
                                                                    v-if="editorMode">mdi-numeric-5-circle</v-icon></span>
                                                            <v-icon
                                                                v-if="graph.users[5] && !(graph.users[5].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[5], graph.users[4], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[5] && graph.users[5].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[5])"
                                                                v-if="graph.users[5] && graph.users[5].placement"
                                                                v-model="graph.users[5].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[5])">{{
            graph.users[5] && graph.users[5].full_name }}
                                                                {{ graph.users[5] && graph.users[5].weight_kg ?
            `(${graph.users[5].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mt-10">
                                                        <v-col :class="graphNodeClass(graph.users[6])">
                                                            <span class="no-print"><v-icon
                                                                    v-if="editorMode">mdi-numeric-6-circle</v-icon></span>
                                                            <v-icon
                                                                v-if="graph.users[6] && !(graph.users[6].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[6], graph.users[7], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[6] && graph.users[6].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[6])"
                                                                v-if="graph.users[6] && graph.users[6].placement"
                                                                v-model="graph.users[6].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[6])">{{
            graph.users[6] && graph.users[6].full_name }}
                                                                {{ graph.users[6] && graph.users[6].weight_kg ?
            `(${graph.users[6].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[7])">
                                                            <span class="no-print"><v-icon
                                                                    v-if="editorMode">mdi-numeric-7-circle</v-icon></span>
                                                            <v-icon
                                                                v-if="graph.users[7] && !(graph.users[7].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[7], graph.users[6], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[7] && graph.users[7].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[7])"
                                                                v-if="graph.users[7] && graph.users[7].placement"
                                                                v-model="graph.users[7].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[7])">{{
            graph.users[7] && graph.users[7].full_name }}
                                                                {{ graph.users[7] && graph.users[7].weight_kg ?
            `(${graph.users[7].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="4" class="level-2">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[2])">
                                                            <span class="no-print"><v-icon
                                                                    v-if="editorMode">mdi-numeric-2-circle</v-icon></span>
                                                            <v-icon
                                                                v-if="graph.users[2] && !(graph.users[2].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[2], graph.users[3], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-else-if="graph.users[2] && graph.users[2].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[2])"
                                                                v-if="graph.users[2] && graph.users[2].placement"
                                                                v-model="graph.users[2].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[2])">{{
            graph.users[2] && graph.users[2].full_name }}
                                                                {{ graph.users[2] && graph.users[2].weight_kg ?
            `(${graph.users[2].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[3])">
                                                            <span class="no-print"><v-icon
                                                                    v-if="editorMode">mdi-numeric-3-circle</v-icon></span>
                                                            <v-icon
                                                                v-if="graph.users[3] && !(graph.users[3].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[3], graph.users[2], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-else-if="graph.users[3] && graph.users[3].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[3])"
                                                                v-if="graph.users[3] && graph.users[3].placement"
                                                                v-model="graph.users[3].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[3])">{{
            graph.users[3] && graph.users[3].full_name }}
                                                                {{ graph.users[3] && graph.users[3].weight_kg ?
            `(${graph.users[3].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="4" class="level-1">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[1])">
                                                            <span class="no-print"><v-icon
                                                                    v-if="editorMode">mdi-numeric-1-circle</v-icon></span>
                                                            <v-icon
                                                                v-if="graph.users[1] && graph.users[1].placement !== null">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[1])"
                                                                v-if="graph.users[1] && graph.users[1].placement !== null"
                                                                v-model="graph.users[1].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[1])">{{
            graph.users[1] && graph.users[1].full_name }}
                                                                {{ graph.users[1] && graph.users[1].weight_kg ?
            `(${graph.users[1].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <!--Sin competidor-->
                <v-tab-item>
                    <v-row v-for="tournamentGraphInfo in aloneTournamenGraphsByCategory"
                        :key="tournamentGraphInfo['graphs'][0]['tournament_category_id']">
                        <v-col cols="12">
                            <h3>
                                {{ tournamentGraphInfo.name }} ({{ tournamentGraphInfo.graphs.length }})
                                <v-icon @click="toggleCategoryDisplay(tournamentGraphInfo.name)"
                                    class="mouse_over_clickable"
                                    v-if="displayedCategories[tournamentGraphInfo.name]">mdi-chevron-up</v-icon>
                                <v-icon @click="toggleCategoryDisplay(tournamentGraphInfo.name)"
                                    class="mouse_over_clickable" v-else>mdi-chevron-down</v-icon>
                            </h3>
                        </v-col>
                        <v-col v-if="displayedCategories[tournamentGraphInfo.name]">
                            <v-row>
                                <v-col v-for="(graph, index) in tournamentGraphInfo.graphs" :key="graph.graph_number"
                                    cols=12>
                                    <v-card>
                                        <v-card-title v-if="graph">
                                            {{ graph['tournament_category_id'] }}.{{ graph['graph_number'] }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[4])">
                                                            <v-icon
                                                                v-if="graph.users[4] && !(graph.users[4].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[4], graph.users[5], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[4] && graph.users[4].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[4])"
                                                                v-if="graph.users[4] && graph.users[4].placement"
                                                                v-model="graph.users[4].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[4])">{{
            graph.users[4] && graph.users[4].full_name }}
                                                                {{ graph.users[4] && graph.users[4].weight_kg ?
            `(${graph.users[4].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[5])">
                                                            <v-icon
                                                                v-if="graph.users[5] && !(graph.users[5].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[5], graph.users[4], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[5] && graph.users[5].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[5])"
                                                                v-if="graph.users[5] && graph.users[5].placement"
                                                                v-model="graph.users[5].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[5])">{{
            graph.users[5] && graph.users[5].full_name }}
                                                                {{ graph.users[5] && graph.users[5].weight_kg ?
            `(${graph.users[5].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mt-10">
                                                        <v-col :class="graphNodeClass(graph.users[6])">
                                                            <v-icon
                                                                v-if="graph.users[6] && !(graph.users[6].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[6], graph.users[7], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[6] && graph.users[6].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[6])"
                                                                v-if="graph.users[6] && graph.users[6].placement"
                                                                v-model="graph.users[6].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[6])">{{
            graph.users[6] && graph.users[6].full_name }}
                                                                {{ graph.users[6] && graph.users[6].weight_kg ?
            `(${graph.users[6].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[7])">
                                                            <v-icon
                                                                v-if="graph.users[7] && !(graph.users[7].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[7], graph.users[6], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[7] && graph.users[7].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[7])"
                                                                v-if="graph.users[7] && graph.users[7].placement"
                                                                v-model="graph.users[7].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[7])">{{
            graph.users[7] && graph.users[7].full_name }}
                                                                {{ graph.users[7] && graph.users[7].weight_kg ?
            `(${graph.users[7].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="4" class="level-2">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[2])">
                                                            <v-icon
                                                                v-if="graph.users[2] && !(graph.users[2].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[2], graph.users[3], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-else-if="graph.users[2] && graph.users[2].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[2])"
                                                                v-if="graph.users[2] && graph.users[2].placement"
                                                                v-model="graph.users[2].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[2])">{{
            graph.users[2] && graph.users[2].full_name }}
                                                                {{ graph.users[2] && graph.users[2].weight_kg ?
            `(${graph.users[2].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[3])">
                                                            <v-icon
                                                                v-if="graph.users[3] && !(graph.users[3].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[3], graph.users[2], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-else-if="graph.users[3] && graph.users[3].placement">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[3])"
                                                                v-if="graph.users[3] && graph.users[3].placement"
                                                                v-model="graph.users[3].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[3])">{{
            graph.users[3] && graph.users[3].full_name }}
                                                                {{ graph.users[3] && graph.users[3].weight_kg ?
            `(${graph.users[3].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="4" class="level-1">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[1])">
                                                            <v-icon
                                                                v-if="graph.users[1] && graph.users[1].placement !== null">mdi-trophy</v-icon>
                                                            <input type="checkbox" @click="assignPrized(graph.users[1])"
                                                                v-if="graph.users[1] && graph.users[1].placement !== null"
                                                                v-model="graph.users[1].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[1])">{{
            graph.users[1] && graph.users[1].full_name }}
                                                                {{ graph.users[1] && graph.users[1].weight_kg ?
            `(${graph.users[1].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <!--Exhibiciones-->
                <v-tab-item>
                    <v-row v-for="tournamentGraphInfo in exhibitionTournamenGraphsByCategory"
                        :key="tournamentGraphInfo['graphs'][0]['tournament_category_id']">
                        <v-col cols="12">
                            <h3>
                                {{ tournamentGraphInfo.name }} ({{ tournamentGraphInfo.graphs.length }})
                                <v-icon @click="toggleCategoryDisplay(tournamentGraphInfo.name)"
                                    class="mouse_over_clickable"
                                    v-if="displayedCategories[tournamentGraphInfo.name]">mdi-chevron-up</v-icon>
                                <v-icon @click="toggleCategoryDisplay(tournamentGraphInfo.name)"
                                    class="mouse_over_clickable" v-else>mdi-chevron-down</v-icon>
                            </h3>
                        </v-col>
                        <v-col v-if="displayedCategories[tournamentGraphInfo.name]">
                            <v-row>
                                <v-col v-for="(graph, index) in tournamentGraphInfo.graphs" :key="graph.graph_number"
                                    cols=12>
                                    <v-card>
                                        <v-card-title v-if="graph">
                                            GRÁFICA: {{ graph['tournament_category_id'] }}.{{ graph['graph_number'] }}
                                            AREA: {{ graph.tournament_category.table_name || 'N/T' }} <v-btn
                                                v-if="editorMode" class="green lighten-2"
                                                @click="changeTable(graph)">Cambiar área</v-btn>
                                            <v-btn v-if="editorMode"
                                                @click="deleteExhibitionGraph(graph.tournament_category_id)"
                                                class="red lighten-2" dark><v-icon>mdi-delete</v-icon></v-btn>
                                            <div class="no-print" v-if="editorMode">
                                                <input type="checkbox" class="no-print"><span
                                                    class="no-print">Imprimir</span>
                                            </div>
                                            <br>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[4])">
                                                            <v-icon
                                                                v-if="graph.users[4] && !(graph.users[4].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[4], graph.users[5], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[4] && graph.users[4].placement">mdi-trophy</v-icon>
                                                            <v-btn v-if="editorMode && graph && graph.users[4]"
                                                                @click="deleteExhibitionIndividualGraph(graph, graph.users[4])"
                                                                class="red lighten-2"
                                                                dark><v-icon>mdi-delete</v-icon></v-btn>
                                                            <input type="checkbox" @click="assignPrized(graph.users[4])"
                                                                v-if="graph.users[4] && graph.users[4].placement"
                                                                v-model="graph.users[4].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[4])">{{
            graph.users[4] && graph.users[4].full_name }}
                                                                {{ graph.users[4] && graph.users[4].weight_kg ?
            `(${graph.users[4].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[5])">
                                                            <v-icon
                                                                v-if="graph.users[5] && !(graph.users[5].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[5], graph.users[4], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[5] && graph.users[5].placement">mdi-trophy</v-icon>
                                                            <v-btn v-if="editorMode && graph && graph.users[5]"
                                                                @click="deleteExhibitionIndividualGraph(graph, graph.users[5])"
                                                                class="red lighten-2"
                                                                dark><v-icon>mdi-delete</v-icon></v-btn>
                                                            <input type="checkbox" @click="assignPrized(graph.users[5])"
                                                                v-if="graph.users[5] && graph.users[5].placement"
                                                                v-model="graph.users[5].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[5])">{{
            graph.users[5] && graph.users[5].full_name }}
                                                                {{ graph.users[5] && graph.users[5].weight_kg ?
            `(${graph.users[5].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="mt-10">
                                                        <v-col :class="graphNodeClass(graph.users[6])">
                                                            <v-icon
                                                                v-if="graph.users[6] && !(graph.users[6].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[6], graph.users[7], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[6] && graph.users[6].placement">mdi-trophy</v-icon>
                                                            <v-btn v-if="editorMode && graph && graph.users[6]"
                                                                @click="deleteExhibitionIndividualGraph(graph, graph.users[6])"
                                                                class="red lighten-2"
                                                                dark><v-icon>mdi-delete</v-icon></v-btn>
                                                            <input type="checkbox" @click="assignPrized(graph.users[6])"
                                                                v-if="graph.users[6] && graph.users[6].placement"
                                                                v-model="graph.users[6].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[6])">{{
            graph.users[6] && graph.users[6].full_name }}
                                                                {{ graph.users[6] && graph.users[6].weight_kg ?
            `(${graph.users[6].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[7])">
                                                            <v-icon
                                                                v-if="graph.users[7] && !(graph.users[7].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[7], graph.users[6], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-if="graph.users[7] && graph.users[7].placement">mdi-trophy</v-icon>
                                                            <v-btn v-if="editorMode && graph && graph.users[7]"
                                                                @click="deleteExhibitionIndividualGraph(graph, graph.users[7])"
                                                                class="red lighten-2"
                                                                dark><v-icon>mdi-delete</v-icon></v-btn>
                                                            <input type="checkbox" @click="assignPrized(graph.users[7])"
                                                                v-if="graph.users[7] && graph.users[7].placement"
                                                                v-model="graph.users[7].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[7])">{{
            graph.users[7] && graph.users[7].full_name }}
                                                                {{ graph.users[7] && graph.users[7].weight_kg ?
            `(${graph.users[7].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="4" class="level-2">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[2])">
                                                            <v-icon
                                                                v-if="graph.users[2] && !(graph.users[2].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[2], graph.users[3], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-else-if="graph.users[2] && graph.users[2].placement">mdi-trophy</v-icon>
                                                            <v-btn v-if="editorMode && graph && graph.users[2]"
                                                                @click="deleteExhibitionIndividualGraph(graph, graph.users[2])"
                                                                class="red lighten-2"
                                                                dark><v-icon>mdi-delete</v-icon></v-btn>
                                                            <input type="checkbox" @click="assignPrized(graph.users[2])"
                                                                v-if="graph.users[2] && graph.users[2].placement"
                                                                v-model="graph.users[2].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[2])">{{
            graph.users[2] && graph.users[2].full_name }}
                                                                {{ graph.users[2] && graph.users[2].weight_kg ?
            `(${graph.users[2].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[3])">
                                                            <v-icon
                                                                v-if="graph.users[3] && !(graph.users[3].placement) && canDecideEncounters"
                                                                @click="setWinner(graph.users[3], graph.users[2], index)">mdi-arrow-up</v-icon>
                                                            <v-icon
                                                                v-else-if="graph.users[3] && graph.users[3].placement">mdi-trophy</v-icon>
                                                            <v-btn v-if="editorMode && graph && graph.users[3]"
                                                                @click="deleteExhibitionIndividualGraph(graph, graph.users[3])"
                                                                class="red lighten-2"
                                                                dark><v-icon>mdi-delete</v-icon></v-btn>
                                                            <input type="checkbox" @click="assignPrized(graph.users[3])"
                                                                v-if="graph.users[3] && graph.users[3].placement"
                                                                v-model="graph.users[3].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[3])">{{
            graph.users[3] && graph.users[3].full_name }}
                                                                {{ graph.users[3] && graph.users[3].weight_kg ?
                                                                `(${graph.users[3].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="4" class="level-1">
                                                    <v-row>
                                                        <v-col :class="graphNodeClass(graph.users[1])">
                                                            <v-icon
                                                                v-if="graph.users[1] && graph.users[1].placement !== null">mdi-trophy</v-icon>
                                                            <v-btn v-if="editorMode && graph && graph.users[1]"
                                                                @click="deleteExhibitionIndividualGraph(graph, graph.users[1])"
                                                                class="red lighten-2"
                                                                dark><v-icon>mdi-delete</v-icon></v-btn>
                                                            <input type="checkbox" @click="assignPrized(graph.users[1])"
                                                                v-if="graph.users[1] && graph.users[1].placement !== null"
                                                                v-model="graph.users[1].graph_result.prized">
                                                            <span class="mouse_over_clickable"
                                                                @click="openStudentDialog(graph.users[1])">{{
                                                                graph.users[1] && graph.users[1].full_name }}
                                                                {{ graph.users[1] && graph.users[1].weight_kg ?
                                                                `(${graph.users[1].weight_kg}KG)` : '' }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <!--Competidores premiados-->
                <v-tab-item>
                    <v-row>
                        <v-col md="8">
                            <v-data-table
                                :headers="[{ text: 'Nombre', value: 'full_name' }, { text: 'Doyang', value: 'doyang_name' }, { text: 'Lugar', value: 'graph_result.placement' }]"
                                :items="prizedUsers" :items-per-page="50" class="elevation-1"></v-data-table>
                        </v-col>
                        <v-col md="4">
                            <ul class="text-left">
                                <li><strong>Primeros lugares:</strong> {{ prizedUsersStats.first }}</li>
                                <li><strong>Segundos lugares:</strong> {{ prizedUsersStats.second }}</li>
                                <li><strong>Terceros lugares:</strong> {{ prizedUsersStats.third }}</li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <!--Competidores sin premiar-->
                <v-tab-item>
                    <v-row>
                        <v-col md="8">
                            <v-data-table
                                :headers="[{ text: 'Nombre', value: 'full_name' }, { text: 'Doyang', value: 'doyang_name' }, { text: 'Lugar', value: 'graph_result.placement' }]"
                                :items="filteredNotPrizedUsers" :items-per-page="50" class="elevation-1"></v-data-table>
                        </v-col>
                        <v-col md="4">
                            <ul class="text-left">
                                <li><strong>Primeros lugares:</strong> {{ notPrizedUsersStats.first }}</li>
                                <li><strong>Segundos lugares:</strong> {{ notPrizedUsersStats.second }}</li>
                                <li><strong>Terceros lugares:</strong> {{ notPrizedUsersStats.third }}</li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <!--Premiaciones de doyang-->
                <v-tab-item>
                    <v-row>
                        <v-col>
                            <h4>Criterios de desempate</h4>
                            <ol>
                                <li>Por primeros lugares compitiendo</li>
                                <li>Por segundos lugares</li>
                                <li>Por terceros lugares</li>
                                <li>Primer lugar sin competir</li>
                            </ol>
                            <p>Si depués de aplicar estos criterios aún existe un empate, se procederá a los siguientes
                                criterios</p>
                            <ol>
                                <li>Por lugares en combate (siguiendo los criterios anteriores)</li>
                                <li>Por lugares en formas (siguiendo los criterios anteriores)</li>
                            </ol>
                            Si después de aplicar estos criterios, el empate continúa, se considera empate.
                        </v-col>
                        <v-col>
                            <v-data-table
                                :headers="[{ text: 'Nombre', value: 'name' }, { text: 'Puntaje', value: 'points' }, { text: 'Lugar', value: 'placement' }]"
                                :items="doyangsPoints" :items-per-page="50" class="elevation-1"
                                :options="{ sortBy: ['placement'], sortDesc: [false] }">
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-row>
        <v-dialog v-model="showStudentDialog" transition="dialog-bottom-transition" max-width="800">
            <!--Title with close-->
            <v-card>
                <v-card-title>
                    <span class="headline">Tarjeta de Competidor</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showStudentDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="studentDialogUser">
                        <v-col cols="12">
                            <ul class="text-left">
                                <li><strong>Nombre: </strong>{{ studentDialogUser.full_name }}</li>
                                <li><strong>Sexo: </strong>{{ studentDialogUser.sex }}</li>
                                <li><strong>Doyang: </strong>{{ studentDialogUser.doyang_name }}</li>
                                <li><strong>Profesor: </strong>{{ studentDialogUser.responsible_name }}</li>
                                <li><strong>Edad: </strong>{{ studentDialogUser.age }} años</li>
                                <li><strong>Estatura: </strong>{{ studentDialogUser.height_m }}</li>
                                <li><strong>Peso: </strong>{{ studentDialogUser.weight_kg }}</li>
                                <li><strong>Grado: </strong>{{ studentDialogUser.degree_name }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="12" v-if="editorMode">
                            <v-row>
                                <v-col cols="6">
                                    <v-btn class="green lighten-2 dark" @click="isolateGraph(false)">Crear gráfica sin
                                        competidor</v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn class="green lighten-2 dark" @click="isolateGraph(true)">Crear gráfica de
                                        exhibición</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="4"><caps-v-text-field :label="'Cambiar a número de gráfica'"
                                                v-model="changeToGraphText"></caps-v-text-field></v-col>
                                        <v-col cols="4"><v-btn class="green lighten-2 dark"
                                                @click="changeGraph"><v-icon>mdi-refresh</v-icon></v-btn></v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="4"><caps-v-text-field :label="'Cambiar a número intragráfica'"
                                                v-model="changeToIntraGraphText"></caps-v-text-field></v-col>
                                        <v-col cols="4"><v-btn class="green lighten-2 dark"
                                                @click="changeIntraGraph"><v-icon>mdi-refresh</v-icon></v-btn></v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import CapsVTextField from '../globals/CapsVTextField.vue'
import PrintTournamentGraphs from './PrintTournamentGraphs.vue'
import ENV from '../../../env';
export default {
    components: {
        CapsVTextField,
        PrintTournamentGraphs
    },
    methods: {
        printAll(result) {
            if (!result.target.checked) {
                this.graphsToPrintIds = []
                return
            }
            for (const graph of this.preparedTournamentGraphs) {
                this.graphsToPrintIds.push(graph.tournament_category_id + '.' + graph.graph_number)
            }
        },
        addGraphToPrintIds(graph, isPush) {
            if (isPush) {
                this.graphsToPrintIds = this.graphsToPrintIds.filter((graphId) => {
                    return graphId != graph.tournament_category_id + '.' + graph.graph_number
                })
                return
            }
            let graphIdString = graph.tournament_category_id + '.' + graph.graph_number
            this.graphsToPrintIds.push(graphIdString)
            return
        },
        shouldGraphBePrinted(graph) {
            return this.graphsToPrintIds.includes(graph.tournament_category_id + '.' + graph.graph_number)
        },
        getInitialGraphCompetitionType() {
            if (this.tournament.has_combat) return 1;
            if (this.tournament.has_forms) return 2;
            if (this.tournament.has_techniques) return 3;
            if (this.tournament.has_jumping_kicks) return 4;
            if (this.tournament.has_boards) return 5;
            if (this.tournament.has_kicking_speed) return 6;
            return null; // or any default value you find suitable
        },
        deleteExhibitionIndividualGraph(graph, user) {
            const confirmDelete = confirm('¿Estás seguro de que quieres eliminar esta gráfica de exhibición?')
            if (!confirmDelete) {
                return
            }
            this.$store.dispatch('Tournament/deleteGraph', `${user.graph_id}`).then(() => {
                alert('Alumno eliminado de la gráfica')
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        deleteExhibitionGraph(tournamentCategoryId) {
            const confirmDelete = confirm('¿Estás seguro de que quieres eliminar esta gráfica de exhibición?')
            if (!confirmDelete) {
                return
            }
            this.$store.dispatch('Tournament/deleteCategory', tournamentCategoryId).then(() => {
                alert('Gráfica eliminada')
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        assignPrized(user) {
            this.$store.dispatch('Tournament/setGraphAssignPrized', `${user.graph_id}/prized?prized=1`).then(() => {
                alert('Competidor premiado')
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        changeTable(graph) {
            const tableName = prompt('Introduce el número de área',)
            if (!tableName) {
                return
            }
            const update = {
                id: graph.tournament_category_id,
                table_name: tableName
            }
            this.$store.dispatch('Tournament/changeGraphTableName', update).then(() => {
                alert('Área cambiada')
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        resetGraphs() {
            this.$store.dispatch('Tournament/show', `${this.$route.params.id}?append=graphs&reset_graph=1`).then((res) => {
                alert(res.message)
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        changeExhibition(event, graph) {
            const payload = {
                id: graph.tournament_category_id,
                is_exhibition: event.target.checked
            }
            this.$store.dispatch('TournamentCategory/update', payload).then((res) => {
                if (event.target.checked) {
                    this.tournamenGraphsByCategory = this.tournamenGraphsByCategory.map((category) => {
                        category.graphs = category.graphs.filter(cateogry_graph => {
                            return graph.graph_number != cateogry_graph.graph_number
                        })
                        return category
                    })
                    this.aloneTournamenGraphsByCategory = this.aloneTournamenGraphsByCategory.map((category) => {
                        category.graphs = category.graphs.filter(cateogry_graph => {
                            return graph.graph_number != cateogry_graph.graph_number
                        })
                        return category
                    })
                    this.exhibitionTournamenGraphsByCategory = this.exhibitionTournamenGraphsByCategory.map((category) => {
                        if (category.name == res.name) {
                            category.graphs.push(graph)
                        }
                        return category
                    })
                }
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        toggleExpandAll() {
            this.expandAll = !this.expandAll
            const displayCategoriesNames = Object.keys(this.displayedCategories)
            for (const name of displayCategoriesNames) {
                this.$set(this, 'displayedCategories', {
                    ...this.displayedCategories,
                    [name]: this.expandAll
                })
            }
        },
        graphNodeClass(user) {
            return user?.graph_result?.changed_graph ? 'graph-node changed-graph' : 'graph-node'
        },
        openStudentDialog(user) {
            this.studentDialogUser = user
            this.graphToChangeId = user?.graph_id
            this.showStudentDialog = !this.showStudentDialog
        },
        toggleCategoryDisplay(name) {
            this.$set(this, 'displayedCategories', {
                ...this.displayedCategories,
                [name]: !this.displayedCategories[name]
            })
        },
        findUserByIntragraphNumber(graph, number) {
            return graph.users.find(user => user.intra_graph_number == number)
        },
        getUserNameByIntragraphNumber(graph, number) {
            return this.findUserByIntragraphNumber(graph, number)?.['full_name']
        },
        getNumberOfStages(x) {
            let exponent = 0
            while (Math.pow(2, exponent) < x) {
                exponent++
            }
            return exponent + 1
        },
        isStillCompeting(user) {
            const losses = user?.graph_result?.['L']?.length || 0
            return losses == 0
        },
        setWinner(winner_user, loser_user, graphIndex) {
            this.$store.dispatch('Tournament/setGraphWin', `${winner_user.graph_id}?winner_id=${winner_user.user_id}&loser_id=${loser_user.user_id}`).then((users) => {
                const newUsers = {}
                Object.keys(users).forEach(key => {
                    const newKey = users[key].intra_graph_number
                    newUsers[newKey] = users[key]
                })
                this.$set(this.tournamentGraphs[graphIndex], 'users', newUsers)
            });
        },
        changeGraph() {
            if (!this.graphToChangeId) {
                return
            }
            let tournamentCategoryId;
            let graphNumber;
            if (this.changeToGraphText.split('.').length !== 2) {
                alert('Gráfica inválida')
                return
            }
            [tournamentCategoryId, graphNumber] = this.changeToGraphText.split('.');
            // get graph that we are going to change to to check if it is exhibition
            const graphToChange = this.dataTournament.graphs.find(graph => graph.tournament_category_id == tournamentCategoryId && graph.graph_number == graphNumber)
            if (graphToChange?.tournament_category?.is_exhibition) {
                const confirmChangeToExhibition = confirm('La gráfica a la que se va a cambiar es de exhibición, ¿estás seguro?')
                if (!confirmChangeToExhibition) {
                    return
                }
            }
            const changeExhibitionText = graphToChange?.tournament_category?.is_exhibition ? '&is_exhibition=1' : '';
            const payload = `${this.graphToChangeId}?tournament_category_id=${tournamentCategoryId}&graph_number=${graphNumber}${changeExhibitionText}`
            this.$store.dispatch('Tournament/changeGraph', payload).then(() => {
                alert('Alumno cambiado de gráfica (favor de verificarlo)')
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        changeIntraGraph() {
            const intraGraphNumber = this.changeToIntraGraphText;
            const payload = `${this.graphToChangeId}?intra_graph_number=${intraGraphNumber}`
            this.$store.dispatch('Tournament/changeGraph', payload).then(() => {
                alert('Alumno cambiado de gráfica (favor de verificarlo)')
            }).catch((error) => {
                alert(error.response.data.message)
            });
        },
        isolateGraph(isExhibition = false) {
            if (!this.graphToChangeId) {
                return
            }
            const isExhibitionText = `is_exhibition=${isExhibition ? 1 : 0}`
            const payload = `${this.graphToChangeId}?${isExhibitionText}`
            this.$store.dispatch('Tournament/isolateGraph', payload).then((res) => {
                alert(res.message)
            }).catch((error) => {
                console.error(error)
            });
        },
        createNumberArray(x) {
            const numberArray = [];
            for (let i = 0; i < x; i++) {
                numberArray.push(i);
            }
            return numberArray;
        },
    },
    mounted() {
        if (this.isGeneralDirection) {
            this.$store.dispatch('Tournament/show', `${this.$route.params.id}?append=graphs&with=tournament_areas`)
        } else {
            this.$store.dispatch('Tournament/show', `${this.$route.params.id}?append=graphs&doyang_id=${this.selectedDoyang.id}&with=tournament_areas`)
        }
        this.$store.dispatch('Tournament/getDoyangPoints', {
            tournamentId: this.$route.params.id,
        });
    },
    computed: {
        canDecideEncounters() {
            return (this.tableName || this.isGeneralDirection)
        },
        tableName() {
            const tournamentAreas = this.tournament?.tournament_areas
            return tournamentAreas?.find(area => area.area_responsible_id == this.authUser?.id)?.area_number
        },
        authUser() {
            return this.$store.getters['Auth/authInfo']?.user
        },
        doyangsPoints() {
            return this.$store.getters['Tournament/doyangsPoints']
        },
        prizedUsers() {
            const prizedUsers = []
            this.preparedTournamentGraphs.forEach(graph => {
                Object.values(graph.users).forEach(user => {
                    if (user.graph_result.prized) {
                        user.graph_result.placement = user.graph_result.placement + 1
                        user.placement = user.graph_result.placement
                        prizedUsers.push(user)
                    }
                })
            })
            return prizedUsers
        },
        filteredPrizedUsers() {
            return this.prizedUsers.filter(user => user.full_name.toLowerCase().includes(this.studentSearch.toLowerCase()))
        },
        prizedUsersStats() {
            const stats = {
                first: 0,
                second: 0,
                third: 0,
            }
            this.prizedUsers.forEach(user => {
                if (user.placement == 1) {
                    stats.first++
                } else if (user.placement == 2) {
                    stats.second++
                } else if (user.placement == 3) {
                    stats.third++
                }
            })
            return stats
        },
        notPrizedUsers() {
            const notPrizedUsers = []
            this.preparedTournamentGraphs.forEach(graph => {
                Object.values(graph.users).forEach(user => {
                    if (!user.graph_result.prized && user.placement != null) {
                        user.graph_result.placement = user.graph_result.placement + 1
                        user.placement = user.graph_result.placement
                        notPrizedUsers.push(user)
                    }
                })
            })
            return notPrizedUsers
        },
        filteredNotPrizedUsers() {
            return this.notPrizedUsers.filter(user => user.full_name.toLowerCase().includes(this.studentSearch.toLowerCase()))
        },
        notPrizedUsersStats() {
            const stats = {
                first: 0,
                second: 0,
                third: 0,
            }
            this.notPrizedUsers.forEach(user => {
                if (user.placement == 1) {
                    stats.first++
                } else if (user.placement == 2) {
                    stats.second++
                } else if (user.placement == 3) {
                    stats.third++
                }
            })
            return stats
        },
        isGeneralDirection() {
            return (this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID)
        },
        selectedDoyang() {
            return this.$store.getters['Auth/selectedDoyang']
        },
        isAbleToPrint() {
            return this.preparedTournamentGraphs?.length > 0
        },
        numberOfCompetitors() {
            return this.tournamentGraphs?.reduce((acc, graph) => {
                return acc + Object.keys(graph.users).length
            }, 0)
        },
        tournament() {
            const tournament = this.$store.getters['Tournament/tournament']
            tournament.graphs = tournament.graphs || []
            return tournament
        },
        dataTournament() {
            return this.tournament
        },
        preparedTournamentGraphs() {
            const tournamentGraphs = []
            this.tournament.graphs.forEach(graph => {
                if (!graph.tournament_category_id)
                    return
                const users = {}
                Object.keys(graph.users).forEach(key => {
                    const newKey = graph.users[key].intra_graph_number
                    users[newKey] = graph.users[key]
                })
                if (this.studentSearch) {
                    const filteredUsers = Object.values(users).filter(user => user.full_name.toLowerCase().includes(this.studentSearch.toLowerCase()))
                    if (!filteredUsers.length)
                        return
                }
                const preparedGraph = {
                    ...graph,
                    users: users,
                }
                tournamentGraphs.push(preparedGraph)
            });
            return tournamentGraphs
        },
        tournamenGraphsByCategory() {
            const tournamenGraphsByCategory = {}
            // const filteredToPrint = this.preparedTournamentGraphs.filter((graph) => {
            //     return graph.competition_type_id == (this.graphCompetitionType)
            // })
            this.preparedTournamentGraphs.filter((graph) => {
                return graph.competition_type_id == (this.graphCompetitionType)
            }).forEach((graph) => {
                if (this.tableName && graph.tournament_category?.table_name && (graph.tournament_category?.table_name != this.tableName))
                    // if (graph.table_name != this.tableName)
                    return
                const tournamentObject = {
                    name: graph.tournament_category_name ?? 'Sin categoría',
                    is_exhibition: graph.tournament_category?.is_exhibition,
                    graphs: [],
                    sex: graph?.tournament_category?.sex,
                    min_degree_id: graph.tournament_category?.min_degree_id,
                    min_age_years: graph.tournament_category?.min_age_years,
                    min_weight_kg: graph.tournament_category?.min_weight_kg,
                };
                if (this.studentSearch) {
                    const filteredUsers = Object.values(graph.users).filter(user => user.full_name.toLowerCase().includes(this.studentSearch.toLowerCase()))
                    if (!filteredUsers.length)
                        return
                }
                if (Object.values(graph.users).length > 1 && !graph.tournament_category?.is_exhibition) {
                    if (!tournamenGraphsByCategory[graph.tournament_category_id])
                        tournamenGraphsByCategory[graph.tournament_category_id] = tournamentObject
                    tournamenGraphsByCategory[graph.tournament_category_id]['graphs'].push(graph)
                }
            })
            return Object.values(tournamenGraphsByCategory).sort((a, b) => {
                const compareSex = (a.sex || 'A').localeCompare(b.sex || 'A');
                if (compareSex !== 0) return compareSex;

                const compareDegree = (a?.min_degree_id || 0) - (b?.min_degree_id || 0);
                if (compareDegree !== 0) return compareDegree;

                const compareAge = (a?.min_age_years || 0) - (b?.min_age_years || 0);
                if (compareAge !== 0) return compareAge;

                const compareWeight = (a?.min_weight_kg || 0) - (b?.min_weight_kg || 0);
                return compareWeight;
            }).filter(category => category.graphs.length > 0)
        },
        exhibitionTournamenGraphsByCategory() {
            const exhibitionTournamenGraphsByCategory = {}
            this.preparedTournamentGraphs.filter(graph => {
                return graph.competition_type_id == (this.graphCompetitionType)
            }).forEach((graph) => {
                const tournamentObject = {
                    name: graph.tournament_category_name ?? 'Sin categoría',
                    is_exhibition: graph.tournament_category?.is_exhibition,
                    graphs: [],
                    sex: graph?.tournament_category?.sex,
                    min_degree_id: graph.tournament_category?.min_degree_id,
                    min_age_years: graph.tournament_category?.min_age_years,
                    min_weight_kg: graph.tournament_category?.min_weight_kg,
                };
                if (this.studentSearch) {
                    const filteredUsers = Object.values(graph.users).filter(user => user.full_name.toLowerCase().includes(this.studentSearch.toLowerCase()))
                    if (!filteredUsers.length)
                        return
                }
                if (graph.tournament_category?.is_exhibition) {
                    if (!exhibitionTournamenGraphsByCategory[graph.tournament_category_id])
                        exhibitionTournamenGraphsByCategory[graph.tournament_category_id] = tournamentObject
                    exhibitionTournamenGraphsByCategory[graph.tournament_category_id]['graphs'].push(graph)
                }
            })
            return Object.values(exhibitionTournamenGraphsByCategory).sort((a, b) => {
                const compareSex = (a.sex || 'A').localeCompare(b.sex || 'A');
                if (compareSex !== 0) return compareSex;

                const compareDegree = (a?.min_degree_id || 0) - (b?.min_degree_id || 0);
                if (compareDegree !== 0) return compareDegree;

                const compareAge = (a?.min_age_years || 0) - (b?.min_age_years || 0);
                if (compareAge !== 0) return compareAge;

                const compareWeight = (a?.min_weight_kg || 0) - (b?.min_weight_kg || 0);
                return compareWeight;
            }).filter(category => category.graphs.length > 0)
        },
        aloneTournamenGraphsByCategory() {
            const aloneTournamenGraphsByCategory = {}
            this.preparedTournamentGraphs.filter(graph => {
                return graph.competition_type_id == (this.graphCompetitionType)
            }).forEach((graph) => {
                const tournamentObject = {
                    name: graph.tournament_category_name ?? 'Sin categoría',
                    is_exhibition: graph.tournament_category?.is_exhibition,
                    graphs: [],
                    sex: graph?.tournament_category?.sex,
                    min_degree_id: graph.tournament_category?.min_degree_id,
                    min_age_years: graph.tournament_category?.min_age_years,
                    min_weight_kg: graph.tournament_category?.min_weight_kg,
                };
                if (Object.values(graph.users).length <= 1 && !graph.tournament_category?.is_exhibition) {
                    if (!aloneTournamenGraphsByCategory[graph.tournament_category_id])
                        aloneTournamenGraphsByCategory[graph.tournament_category_id] = tournamentObject
                    aloneTournamenGraphsByCategory[graph.tournament_category_id]['graphs'].push(graph)
                }
            })
            return Object.values(aloneTournamenGraphsByCategory).sort((a, b) => {
                const compareSex = (a.sex || 'A').localeCompare(b.sex || 'A');
                if (compareSex !== 0) return compareSex;

                const compareDegree = (a?.min_degree_id || 0) - (b?.min_degree_id || 0);
                if (compareDegree !== 0) return compareDegree;

                const compareAge = (a?.min_age_years || 0) - (b?.min_age_years || 0);
                if (compareAge !== 0) return compareAge;

                const compareWeight = (a?.min_weight_kg || 0) - (b?.min_weight_kg || 0);
                return compareWeight;
            }).filter(category => category.graphs.length > 0)
        },
    },
    watch: {
        tournament: {
            deep: true,
            handler() {
                this.graphCompetitionType = this.getInitialGraphCompetitionType();
            }
        }
    },
    data() {
        return {
            graphsToPrintIds: [],
            graphCompetitionType: null,
            editorMode: null,
            changeToIntraGraphText: null,
            studentSearch: '',
            graphTypeSelected: 0,
            graphToChangeId: null,
            changeToGraphText: null,
            expandAll: false,
            studentDialogUser: null,
            showStudentDialog: false,
            displayedCategories: {},
            tournamentGraphs: null,
            // dataTournament: null,
            // tournamenGraphsByCategory: null,
            // aloneTournamenGraphsByCategory: [],
            // exhibitionTournamenGraphsByCategory: [],
        }
    }
}
</script>

<style scoped>
.category-button {
    margin: 5px;
    border-radius: 5px;
    color: white;
}

.dark {
    color: white;
}

.mouse_over_clickable {
    cursor: pointer;
}

.changed-graph {
    background-color: #f88e8e;
    color: white;
}

.graph-node {
    font-size: 9pt;
    border-width: 2px;
    border-style: solid;
    height: 50px;
    text-align: left;
}

.level-2 {
    margin-top: 50px;
}

.level-1 {
    margin-top: 75px;
}

/* checkbox to be bigger */
input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 5px;
    margin-left: 5px;
}

@media print {
    .no-print {
        display: none;
    }
}
</style>
