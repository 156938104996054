import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    tournaments: [],
    tournament: {
        graphs: [],
    },
    cronograms: [],
    doyangsPoints: [],
  },
  mutations: {
    delete(state, id) {
        state.tournaments = state.tournaments.filter((tournament) => {
            return tournament.id != id
        });
    },
    isolateGraph(state, payload) {
        const newGraph = payload?.new_graph;
        const oldGraph = payload?.old_graph;
        console.log('newGraph', newGraph)
        console.log('oldGraph', oldGraph)
        state.tournament.graphs.push(newGraph)
        state.tournament.graphs = state.tournament.graphs.map((graph) => {
            if (graph.tournament_category_id == oldGraph.tournament_category_id) {
                graph.users = oldGraph.users
            }
            return graph
        });
    },
    changeTableName(state, tournamentCategory) {
        const tournamentCategoryId = tournamentCategory.id
        state.tournament.graphs = state.tournament.graphs.map((graph) => {
            if (graph.tournament_category_id == tournamentCategoryId) {
                graph.tournament_category.table_name = tournamentCategory.table_name
                console.log('graph', graph)
            }
            return graph
        });
    },
    index(state, payload) {
      state.tournaments = payload
    },
    show(state, payload) {
      console.log('payload', payload)
      state.tournament = payload
    },
    addProspect(state, payload) {
        let candidateAdded = {}
        state.tournament.candidates = state.tournament.candidates.filter((candidate) => {
          if (payload.userId == candidate.id) {
            candidateAdded = payload.user
          }
            return payload.userId != candidate.id
        })
        state.tournament.prospects.push(candidateAdded)
        state.tournament.doyangProspects.push(candidateAdded)
    },
    deleteProspect(state, payload) {
        let deletedProspect = {}
        state.tournament.prospects = state.tournament.prospects.filter((prospect) => {
          if (payload.userId == prospect.id) {
            deletedProspect = Object.assign(prospect)
          }
          return payload.userId != prospect.id
        })
        state.tournament.doyangProspects = state.tournament.doyangProspects.filter((prospect) => {
          if (payload.userId == prospect.id) {
            deletedProspect = Object.assign(prospect)
          }
          return payload.userId != prospect.id
        })
        state.tournament.candidates.push(deletedProspect)
    },
    addResult (state, {userId, resultId}) {
        state.tournament.prospects.forEach((user) => {
          if (user.id == userId) {
            user.pivot.tournament_result_id = resultId
          }
        })
    },
    commit (state, payload) {
        console.log(state.tournament, payload)
    },
    saveWinGraph (state, payload) {
        console.log('saveWinGraph')
        state.tournament.graphs.map((graph) => {
            if (graph.tournament_category_id == payload?.[0].tournament_category_id) {
                console.log('graph', graph)
                console.log('payload', payload)
                graph.users = payload
            }
            return graph
        })
        console.log(state.tournament, payload)
    },
    commitGraphChanged (state, payload) {
        const oldGraph = payload.old_graph
        const newGraph = payload.new_graph
        state.tournament.graphs = state.tournament.graphs.map((graph) => {
            if (
                graph.tournament_category_id == oldGraph.tournament_category_id
                && graph.graph_number == oldGraph.graph_number
            ) {
                console.log('oldgraphfound')
                console.log('previous users', graph.users)
                console.log('newusers', oldGraph.users)
                graph.users = oldGraph.users
            }

            if (
                graph.tournament_category_id == newGraph.tournament_category_id
                && graph.graph_number == newGraph.graph_number
            ) {
                console.log('newgraphfound')
                graph.users = newGraph.users
            }
            return graph
         });
    },
    indexCronograms(state, payload) {
        state.cronograms = payload
    },
    setDoyangPoints(state, payload) {
        state.doyangsPoints = payload
    },
    resetCronograms(state, payload) {
      state.cronograms = payload
    },
    deleteCategory(state, payload) {
      state.tournament.graphs = state.tournament.graphs.filter((graph) => {
          return graph.tournament_category_id != payload.id
      })
    },
    deleteGraph(state, {graph_id, new_users}) {
        console.log('graph_id', graph_id)
        console.log('new_users', new_users)
        const correctGraph = state.tournament.graphs.find((graph) => {
            const filteredUser = graph.users.find((user) => {
              return user.graph_id == graph_id
            })
            return filteredUser
        })
        correctGraph.users = new_users
    }
  },
  actions: {
    assignResult ({commit}, payload) {
        return axios.post(`${ENV.API_URL}/api/tournaments/${payload.tournamentId}/prospects/${payload.userId}/result`, payload).then((res) => {
          commit('addResult', res)
        })
    },
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/tournaments${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    downloadFile({ commit }, payload) {
      return axios({
            url: `${ENV.API_URL}/api/tournaments${payload ? payload : ''}`,
            method: 'GET',
            responseType: 'blob',
        }).then((res) => {
            commit('index', res.data)
            return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/tournaments/${payload ? payload : ''}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/tournaments`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/tournaments/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    addProspect({commit}, payload) {
        return axios.post(`${ENV.API_URL}/api/tournaments/${payload.tournamentId}/prospects`, {userId : payload.userId}).then((res) => {
            commit('addProspect', res.data)
            return res.data
        }).catch(() => 0)
    },
    deleteProspect({commit}, payload) {
        return axios.delete(`${ENV.API_URL}/api/tournaments/${payload.tournamentId}/prospects/${payload.userId}`, payload).then((res) => {
          commit('deleteProspect', res.data)
          return res.data
        }).catch(() => 0)
    },
    printSheet({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/tournaments/result_sheet/${payload}`, {crossDomain: true, responseType: 'blob'}).then((response) => {
          commit('empty')
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'resultados_torneo.pdf'
          link.click()
          return true
        }).catch(() => 0)
    },
    setGraphWin({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/tournament_graphs/${payload}`).then((res) => {
            commit('saveWinGraph', res.data)
            return res.data
        })
    },
    changeGraph({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/tournament_graphs/change_graph/${payload}`).then((res) => {
            commit('commitGraphChanged', res.data)
            return res.data
        })
    },
    setGraphAssignPrized({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/tournament_graphs/${payload}`).then((res) => {
            commit('saveWinGraph', res.data)
            return res.data
        })
    },
    isolateGraph({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/tournament_graphs/isolate_graph/${payload}`).then((res) => {
            console.log('isolateGraph', res.data)
            commit('isolateGraph', res.data)
            return res.data
        })
    },
    changeGraphTableName({commit}, payload) {
        return axios.put(`${ENV.API_URL}/api/tournament_categories/${payload.id}`, payload).then((res) => {
            commit('changeTableName', res.data)
            return res.data
        })
    },
    indexCronograms({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/tournament_cronograms${payload}`).then((res) => {
            commit('indexCronograms', res.data)
            return res.data
        })
    },
    autogenerateCronograms({commit}, tournamentId) {
        return axios.get(`${ENV.API_URL}/api/tournament_cronograms/tournament/${tournamentId}/autogenerate`).then((res) => {
            commit('indexCronograms', res.data)
            return res.data
        })
    },
    addCronogramRecess({commit}, payload) {
        return axios.post(`${ENV.API_URL}/api/tournament_cronograms`, payload).then((res) => {
            commit('resetCronograms', res.data)
            return res.data
        })
    },
    getDoyangPoints({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/tournaments/${payload.tournamentId}/doyang_results`).then((res) => {
            commit('setDoyangPoints', res.data)
            return res.data
        })
    },
    deleteCategory({commit}, payload) {
        return axios.delete(`${ENV.API_URL}/api/tournament_categories/${payload}`).then((res) => {
            commit('deleteCategory', res.data)
            return res.data
        })
    },
    deleteGraph({commit}, payload) {
        return axios.delete(`${ENV.API_URL}/api/tournament_graphs/${payload}`).then((res) => {
            commit('deleteGraph', {
              graph_id: payload,
              new_users: res.data
            })
            return res.data
        })
    },
    delete({commit}, payload) {
        return axios.delete(`${ENV.API_URL}/api/tournaments/${payload}`).then((res) => {
            commit('delete', payload)
            return res.data
        })
    }
  },
  getters: {
    tournaments: state => {
      return state.tournaments
    },
    tournament: state => {
      return state.tournament
    },
    cronograms: state => {
        return state.cronograms
    },
    doyangsPoints: state => {
        return state.doyangsPoints
    }
  },
  modules: {
  }
}
