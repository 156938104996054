<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>ESTADÍSTICA</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="doyang.fixed_income"><strong>Ingreso fijo:</strong>{{
                currencyFormatter.format(doyang.fixed_income) }}</v-col>
            <v-col v-if="doyang.expenses_midpoint"><strong>Punto de equilibrio:</strong>{{ doyang.expenses_midpoint
                }}</v-col>
            <v-col>
                <v-checkbox v-model="isShowingOnlyThisBimester" :label="'Este bimestre'"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-tabs v-model="selectedTab">
                    <v-tab v-for="tab in tabs" :key="tab.id">
                        {{ tab }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="selectedTab">
                    <!--Eventos-->
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete :labels="[]" v-model="selectedParent" outlined :items="parentEvents"
                                    item-text="name" return-object label="Evento Padre" chips></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row v-if="parentEventInformation">
                            <v-col>
                                <v-data-table :headers="headers" :items="parentEventInformation" :items-per-page="10"
                                    class="elevation-1"></v-data-table>
                            </v-col>
                            <v-col v-if="parentEventInformationParsed">
                                <BarChart :width="100" :height="100" :dataArray="parentEventInformationParsed" />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!--Inscripciones-->
                    <v-tab-item>
                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col v-if="inscriptionsDictParsed">
                                <BarChart :width="100" :height="100" :dataArray="inscriptionsDictParsed['datasets']"
                                    :labels="inscriptionsDictParsed['labels']" />
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                    </v-tab-item>
                    <!--Gastos-->
                    <v-tab-item>
                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col v-if="expensesParsed">
                                <BarChart :width="100" :height="100" :dataArray="expensesParsed['datasets']"
                                    :labels="expensesParsed['labels']" />
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                    </v-tab-item>
                    <!--Mensualidades-->
                    <v-tab-item v-if="!isGeneralDirection">
                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col v-if="monthlyFeesDictParsed">
                                <BarChart :width="100" :height="100" :dataArray="monthlyFeesDictParsed['datasets']"
                                    :labels="monthlyFeesDictParsed['labels']" />
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BarChart from './BarChart.vue';
import env from '../../../env';

export default {
    components: {
        BarChart
    },
    name: 'Statistics',
    methods: {

    },
    data() {
        return {
            env,
            isShowingOnlyThisBimester: false,
            selectedParent: null,
            selectedTab: 0,
            parentEventInformation: null,
            parentEventInformationParsed: null,
            headers: [
                { text: 'Evento', value: 'event_name' },
                { text: 'Asistencias', value: 'count' },
            ]
        };
    },
    watch: {
        selectedParent: async function (parentEvent) {
            if (parentEvent) {
                const info = await this.$store.dispatch('ParentEvent/show', `${parentEvent.id}?append=assistances&bimester_only=${this.isShowingOnlyThisBimester ? '1' : '0'}&doyang_id=${this.selectedDoyang.id}`).then((response) => {
                    return {
                        assistances: response.assistances,
                        exams: response.exams,
                    };
                });

                const assistances = info.assistances

                this.parentEventInformation = assistances;

                // Ensure the parsed data is a new array
                let tempParentEventInformation = [{}];
                for (let i = 0; i < assistances.length; i++) {
                    console.log('assistances[i].event_name', assistances[i].event_name)
                    tempParentEventInformation[0][assistances[i].event_name] = assistances[i].count;
                }

                // Assign a new array to ensure reactivity
                this.parentEventInformationParsed = [...tempParentEventInformation];
            }
        },
        isShowingOnlyThisBimester: async function (isShowingOnlyThisBimester) {
            if (this.selectedParent) {
                const info = await this.$store.dispatch('ParentEvent/show', `${this.selectedParent.id}?append=assistances&bimester_only=${isShowingOnlyThisBimester ? '1' : '0'}&doyang_id=${this.selectedDoyang.id}`).then((response) => {
                    return response.assistances;
                });

                this.parentEventInformation = info;

                // Ensure the parsed data is a new array
                let tempParentEventInformation = [{}];
                for (let i = 0; i < info.length; i++) {
                    tempParentEventInformation[0][info[i].event_name] = info[i].count;
                }

                // Assign a new array to ensure reactivity
                this.parentEventInformationParsed = [...tempParentEventInformation];
            }
        }
    },
    computed: {
        tabs() {
            const tabs = [
                'Eventos', 'Inscripciones', 'Gastos'
            ]
            if (!this.isGeneralDirection) {
                tabs.push('Mensualidades')
            }
            return tabs
        },
        isGeneralDirection() {
            return this.doyang.id == env.DIRECCIÓN_GENERAL_ID
        },
        currencyFormatter() {
            return new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
        },
        doyang() {
            return this.$store.getters['Doyang/doyang']
        },
        expensesParsed() {
            let datasets = {}
            if (!this.doyang.statistics_expenses_by_month) {
                return {}
            }
            Object.keys(this.doyang.statistics_expenses_by_month).forEach((key) => {
                let year = key.split('-')[0]
                let month = key.split('-')[1]
                if (!datasets[year]) {
                    datasets[year] = {}
                }
                datasets[year][month] = this.doyang.statistics_expenses_by_month[key]
            });
            return {
                labels: Object.keys(datasets),
                datasets: Object.values(datasets)
            }
        },
        inscriptionsDictParsed() {
            let datasets = {}
            if (!this.doyang.statistics_inscriptions_by_month) {
                return {}
            }
            Object.keys(this.doyang.statistics_inscriptions_by_month).forEach((key) => {
                let year = key.split('-')[0]
                let month = key.split('-')[1]
                if (!datasets[year]) {
                    datasets[year] = {}
                }
                datasets[year][month] = this.doyang.statistics_inscriptions_by_month[key]
            });
            const dictToReturn = {
                labels: Object.keys(datasets),
                datasets: Object.values(datasets)
            }
            return dictToReturn
        },
        monthlyFeesDictParsed() {
            let datasets = {}
            if (!this.doyang.statistics_monthly_fees_by_month) {
                return {}
            }
            Object.keys(this.doyang.statistics_monthly_fees_by_month).forEach((key) => {
                let year = key.split('-')[0]
                let month = key.split('-')[1]
                if (!datasets[year]) {
                    datasets[year] = {}
                }
                datasets[year][month] = this.doyang.statistics_monthly_fees_by_month[key]
            });
            return {
                labels: Object.keys(datasets),
                datasets: Object.values(datasets)
            }
        },
        parentEvents() {
            return this.$store.getters['ParentEvent/parentEvents']
        },
        selectedDoyang() {
            if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && (this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
                return {
                    name: 'DIRECCIÓN GENERAL'
                }
            } else if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && !(this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
                return {
                    name: 'SIN DOYANG SELECCIONADO'
                }
            }
            return this.$store.getters['Auth/selectedDoyang']
        },
    },
    mounted() {
        this.$store.dispatch('ParentEvent/index')
        this.$store.dispatch('Doyang/show', `${this.selectedDoyang.id}?append=statistics_expenses_by_month,statistics_inscriptions_by_month,statistics_monthly_fees_by_month,expenses_midpoint,fixed_income`)
    },
};
</script>

<style scoped>
/* Your component's styles go here */
</style>