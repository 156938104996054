<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>ADMINISTRADOR DE PERMISOS (DIRECCIÓN GENERAL)</h2>
        <v-row>
          <v-col class="center">
            <v-radio-group v-model="workingPermissionsType">
              <v-radio label="Rol" :value="1"></v-radio>
              <v-radio :value="2" label="Usuarios"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-autocomplete :items="users" v-if="workingPermissionsType == 2" outlined :label="'Usuario'" item-value="id"
          item-text="full_name" v-model="selected_user" return-object></v-autocomplete>
        <v-autocomplete :items="ENV.ROLE_ARRAY" v-if="workingPermissionsType == 1" outlined :label="'Rol'"
          item-value="id" item-text="name" v-model="selected_role" return-object></v-autocomplete>
        <v-data-table height="75vh" v-if="showTable && permissions" :items="workingPermissions" :headers="headers"
          :items-per-page="50" fixed-header>
          <template v-slot:[`item`]="{ item }">
            <tr v-if="!item.separator">
              <td>{{ item.name }}</td>
              <td v-if="!!readPermission(item.name, 'read')"><input v-if="!item.disabledRead" class="big-checkbox"
                  type="checkbox" :checked="true" @click="updatePermission(item, 'read')" /></td>
              <td v-else><input class="big-checkbox" type="checkbox" v-if="!item.disabledRead" :checked="false"
                  @click="updatePermission(item, 'read')" /></td>
              <td v-if="!!readPermission(item.name, 'update')"><input v-if="!item.disabledUpdate" class="big-checkbox"
                  type="checkbox" :checked="true" @click="updatePermission(item, 'update')" /></td>
              <td v-else><input class="big-checkbox" type="checkbox" v-if="!item.disabledUpdate" :checked="false"
                  @click="updatePermission(item, 'update')" /></td>
              <td v-if="!!readPermission(item.name, 'create')"><input v-if="!item.disabledCreate" class="big-checkbox"
                  type="checkbox" :checked="true" @click="updatePermission(item, 'create')" /></td>
              <td v-else><input class="big-checkbox" type="checkbox" v-if="!item.disabledCreate" :checked="false"
                  @click="updatePermission(item, 'create')" /></td>
              <td v-if="!!readPermission(item.name, 'delete')"><input v-if="!item.disabledDelete" class="big-checkbox"
                  type="checkbox" :checked="true" @click="updatePermission(item, 'delete')" /></td>
              <td v-else><input class="big-checkbox" type="checkbox" v-if="!item.disabledDelete" :checked="false"
                  @click="updatePermission(item, 'delete')" /></td>
            </tr>
            <tr v-else>
              <td colspan="9"><strong>{{ item.name }}</strong></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENV from '../../../env'
export default {
  mounted() {
    this.workingPermissionsTemplate = JSON.parse(JSON.stringify(this.workingPermissions))
    let payload = `?active=1&role_id=${ENV.ROLE_PUBLIC_RELATIONS},${ENV.ROLE_LINK_AND_ORIENTATION},${ENV.ROLE_DEPENDANT_KYOSA_ID},${ENV.ROLE_CLUB_KYOSA_ID},${ENV.ROLE_DOYANG_KYOSA_ID},${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_CERTIFIED_PROFESSOR_ID},${ENV.ROLE_TECHNICAL_DIRECTOR},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_ADMINISTRATIVE_DIRECTOR},${ENV.ROLE_PROGRAMMER}&with=degree,role`
    this.$store.dispatch('Student/index', payload);
    this.$store.dispatch('Permission/index');
  },
  props: {
    doyangId: Number
  },
  computed: {
    showTable() {
      return this.selected_role || this.selected_user
    },
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang']
    },
    permissions() {
      const permissions = this.$store.getters['Permission/permissions']
      let nameInPermissions = {}
      Object.keys(permissions).forEach((index) => {
        const permission = permissions[index]
        nameInPermissions[permission.name] = {
          read: permission.read || false,
          update: permission.update || false,
          delete: permission.delete || false,
          create: permission.create || false
        }
      })
      return nameInPermissions
    },
    users() {
      return this.$store.getters['Student/students'].filter(user => user.active)
    },
  },
  watch: {
    selected_user() {
      this.setWorkingPermissions(JSON.parse(JSON.stringify(this.workingPermissionsTemplate)))
      const payload = `?user_id=${this.selected_user.id}`;
      this.$store.dispatch('Permission/index', payload).then(() => { console.log('Permissions updated') })
    },
    selected_role() {
      this.setWorkingPermissions(JSON.parse(JSON.stringify(this.workingPermissionsTemplate)))
      const payload = `?role_id=${this.selected_role.id}`;
      this.$store.dispatch('Permission/index', payload).then(() => { console.log('Permissions updated') })
    },
    workingPermissionsType() {
      this.selected_user = null
      this.selected_role = null
    }
  },
  methods: {
    readPermission(permission, type) {
      return this.permissions?.[permission]?.[type] || false
    },
    updatePermission(permission, value) {
      // Role
      if (this.workingPermissionsType == 1) {
        this.$store.dispatch('Permission/create', {
          name: permission.name,
          type: value,
          role_id: this.selected_role.id
        });
      }
      // User
      if (this.workingPermissionsType == 2) {
        this.$store.dispatch('Permission/create', {
          name: permission.name,
          type: value,
          user_id: this.selected_user.id
        });
      }
      permission.name
    },
    setWorkingPermissions(permissions) {
      this.$set(this, "workingPermissions", [])
      setTimeout(() => {
        this.$set(this, "workingPermissions", permissions)
      }, 750)
    }
  },
  data() {
    return {
      workingPermissionsTemplate: null,
      workingPermissionsType: 1,
      selected_user: null,
      selected_role: null,
      ENV,
      doyang: null,
      user: null,
      workingPermissions: [
        {
          name: 'DIRECCIÓN GENERAL',
          separator: true
        },
        {
          name: 'Modifica Roles',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Usuarios',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Doyangs',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Multinivel Profesores',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Tablas Comisiones',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Grados',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Alumnos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Sedes',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Honorificos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Localidades',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Productos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Sinodales',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Categories de doyang',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Validación de examen',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Otros requisitos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Tareas bimestrales',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Permisos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Torneos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Eventos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Examen',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'DOYANG AREA TÉCNICA',
          separator: true
        },
        {
          name: 'Asistencias',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Asistencias Extemporáneas (72hrs)',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: false,
          disabledRead: true,
          disabledDelete: true,
        },
        {
          name: 'Doyang Alumnos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Doyang Grupos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Doyang Torneos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: true,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Doyang Eventos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: true,
          disabledRead: false,
          disabledDelete: true,

        },
        {
          name: 'Doyang Examen',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: true,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'DOYANG AREA ADMINISTRATIVA',
          separator: true
        },
        {
          name: 'Recursos Digitales',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Doyang Tareas Bimestrales',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Doyang Personal',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Seguros',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Ventas',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: true,
          disabledRead: true,
          disabledDelete: true,
        },
        {
          name: 'Gastos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: false,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        },
        {
          name: 'Contabilidad',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: true,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Inventario',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: true,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Entradas/Salidas',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: true,
        },
        {
          name: 'Recibos',
          create: 0,
          delete: 0,
          udpate: 0,
          read: 0,
          disabledCreate: true,
          disabledUpdate: false,
          disabledRead: false,
          disabledDelete: false,
        }
      ],
      headers: [
        { text: 'Permiso', value: 'name' },
        { text: 'Leer', value: 'read' },
        { text: 'Actualizar', value: 'update' },
        { text: 'Crear', value: 'create' },
        { text: 'Borrar', value: 'delete' },
      ],
    }
  },
}
</script>

<style>
.big-checkbox {
  width: 25px;
  height: 25px;
}
</style>
