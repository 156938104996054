<script>
var debounce = require('debounce');
import ENV from '../../../env'
import CapsVTextField from '../globals/CapsVTextField.vue';
import DMYDateField from '../globals/DMYDateField.vue';

export default {
  beforeRouteLeave(to, from, next) {
    if (this.checkModifications()) {
      return next()
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    CapsVTextField,
    DMYDateField
  },
  mounted: function () {
    this.$store.dispatch(`DoyangSurrogation/index`)
    const payloadForProfessors = `?role_id=${ENV.ROLE_PUBLIC_RELATIONS},${ENV.ROLE_LINK_AND_ORIENTATION},${ENV.ROLE_DEPENDANT_KYOSA_ID},${ENV.ROLE_CLUB_KYOSA_ID},${ENV.ROLE_DOYANG_KYOSA_ID},${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_CERTIFIED_PROFESSOR_ID},${ENV.ROLE_TECHNICAL_DIRECTOR},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_ADMINISTRATIVE_DIRECTOR},${ENV.ROLE_PROGRAMMER}&with=degree,role`;
    this.$store.dispatch('Professor/index', payloadForProfessors)
    this.$store.dispatch('Student/index', '?select[0]=name&select[1]=last_name&select[2]=second_last_name&select[2]=id&select[3]=doyang_id&select[4]=degree_id').then((res) => {
      this.user.recomended_by.id = res?.recomended_by
    })
    this.$store.dispatch('Degree/index')
    this.$store.dispatch('Doyang/index', 'with=personal,responsible&append=all_personal')
    if (this.$route.params.id) {
      this.$store.dispatch('Payment/index', `?user_id=${this.$route.params.id}&orderBy[id]=desc&limit=10&with=receipt,receipt.payments`)
      this.$store.dispatch('Student/show', `${this.$route.params.id}?with=groupsAsStudent,degree,doyang,has_recommended,child_pickers&append=required_points_for_degree,last_covered_month_parsed,available_bundles,point_events,non_assisted_events`).then((res) => {
        this.user.revalidating = res.revalidating == "1"
        this.user.doyang_id = res.doyang_id ? parseInt(res.doyang_id) : this.selectedDoyang.id
        this.user.doyang_bundle_id = res.doyang_bundle_id ? parseInt(res.doyang_bundle_id) : null
        this.$store.dispatch('DoyangGroup/index', `?doyang_id=${this.user.doyang_id}`).then(() => {
          const groupId = this.user?.groups_as_student?.[0]?.id
          this.user.doyang_group = { id: groupId }
        })
        this.user.password = null
        this.user.enrollment_date = res.enrollments?.[res.enrollments.length - 1]?.enrollment_date
        this.user.start_tkd_date = res.start_tkd_date
        this.user.disenroll_date = res.enrollments?.[res.enrollments.length - 1]?.disenroll_date
        this.originalEnrollment = !!this.user.enrollment_date
        const groupId = this.user?.groups_as_student?.[0]?.id
        this.user.doyang_group = { id: groupId }
        this.originalUser = JSON.parse(JSON.stringify(this.user))
      })
    } else {
      this.user.doyang_id = this.selectedDoyang && this.selectedDoyang.id ? this.selectedDoyang.id : null
      this.user.role_id = 10
      this.user.degree_id = 1
      this.$store.dispatch('DoyangGroup/index', `?doyang_id=${this.user.doyang_id}`).then(() => {
        this.user.doyang_group.id = null
        // this.user.doyang_group.id = groups[0].id
        this.originalUser = this.user
      })
    }
  },
  methods: {
    downloadCurriculumCsv() {
      this.$store.dispatch('Student/downloadCurriculumCsv', this.user.id).then((res) => {
        this.downloadStringInNewPage(res, 'curriculum.csv')
      });
    },
    downloadStringInNewPage(string, filename) {
      const bom = '\uFEFF';  // UTF-8 BOM
      const blob = new Blob([bom + string], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    goToDoyangGroup() {
      this.$router.push({ name: 'DoyangGroupAddPeople', params: { doyangId: this.user.doyang_id } })
    },
    addChildPickers() {
      console.log('this.user.child_pickers', this.user.child_pickers)
      this.user.child_pickers.push(this.childPickerEdit);
      // reset this.childPickerEdit properties
      this.childPickerEdit = {
        name: '',
        kinship: '',
        telephone: '',
      }

    },
    deleteChildPicker(name) {
      this.user.child_pickers = this.user.child_pickers.filter((childPicker) => childPicker.name != name)
    },
    openDisenrollmentDatePicker(id) {
      this.showDisenrollmentDatePicker = true
      this.editEnrollmentId = id
    },
    udpateEnrollment() {
      this.$store.dispatch('Enrollment/update', {
        id: this.editEnrollmentId,
        enrollment_date: this.editEnrollment.enrollment_date,
        disenroll_date: this.editEnrollment.disenroll_date
      }).then((resEnrollment) => {
        const enrollments = this.user.enrollments.map((enrollment) => {
          if (enrollment.id == this.editEnrollmentId) {
            enrollment.enrollment_date_parsed = resEnrollment.enrollment_date_parsed
            enrollment.disenroll_date = resEnrollment.disenroll_date
          }
          return enrollment
        })
        this.showDisenrollmentDatePicker = false
        this.editEnrollmentId = null
        this.editEnrollment = { disenroll_date: null, enrollment_date: null }
        this.$set(this.user, 'enrollments', enrollments)
      })
    },
    deleteEnrollment(id) {
      if (!confirm('¿Estás seguro que deseas eliminar esta inscripción?')) return
      this.$store.dispatch('Enrollment/delete', id).then(() => {
        this.user.enrollments = this.user.enrollments.filter((enrollment) => enrollment.id != id)
      })
    },
    cancelReceipt(id) {
      if (!confirm('¿Deseas cancelar este recibo?')) return
      this.$store.dispatch(
        `Receipt/update`,
        {
          id,
          is_canceled: true
        }
      );
    },
    printReceipt(id) {
      this.$store.dispatch(`Receipt/print`, `${id}?print=1&no_format=0`)
    },
    checkModifications() {
      if (this.pendingChanges) {
        return confirm('¿Tienes cambios pendientes, estás seguro que quieres salir?')
      }
      return true
    },
    reloadGroups(newId) {
      this.$store.dispatch('DoyangGroup/index', `?doyang_id=${newId}`)
    },
    getAgeInYears(birthDate) {
      if (!birthDate) {
        return '';
      }
      var today = new Date();
      birthDate = new Date(birthDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    activate() {
      const user = { id: this.user.id, active: this.user.active }
      const message = this.user.active ? 'activar' : 'desactivar'
      console.log('newState', 'activating')
      const active = this.user.active
      if (confirm(`¿Estás seguro que deseas ${message} a este alumno?`)) {
        console.log('newState', 'sending')
        this.$store.dispatch('Student/update', user)
      } else {
        console.log('setting timeout')
        setTimeout(() => {
          this.user.active = !active
        }, 500)
      }
    },
    createNewStudent: async function (event, comesFromDialog = false) {
      if (!this.isProspect && !this.user.doyang_group.id) {
        alert('Debes seleccionar un grupo para el alumno (o seleccionar prospecto)')
        return
      }
      const ageInYears = this.getAgeInYears(this.user.birth_date)
      this.user.blood_type = this.user.blood_type == "No especifcado" ? null : this.user.blood_type
      if (!this.user.start_payments_date) {
        alert('El primer mes que se cobra es obligatorio (Ver sección Otros Datos)')
        return
      }
      if (ageInYears < 18 && (!((this.user.father_name
        && this.user.father_cellphone)
        || (this.user.mother_name
          && this.user.mother_cellphone))
      )) {
        alert('Si el alumno es menor de edad debes llenar los campos de nombre y teléfono de padre o madre')
        return
      }
      if (!this.user.cellphone && ageInYears >= 18) {
        alert('Debes llenar el campo de celular si el alumno es mayor de edad')
        return
      }
      if (this.$refs.form.validate() !== true) {
        this.toggleSubmitError()
        return
      }
      if (!comesFromDialog) {
        await this.$store.dispatch('Student/index', `?validate=1&name=${this.user.name}&last_name=${this.user.last_name}&birth_date=${this.user.birth_date}&with=doyang`).then(res => {
          if (res.length > 0) {
            this.dialogStudentExist = true
            this.existingStudents = res
          }
        })
        if (this.dialogStudentExist) {
          return
        }
      }
      this.$store.dispatch('Student/create', this.user).then(() => {
        this.toggleCreatedSuccess()
        alert('Se ha creado el registro con éxito')
        this.pendingChanges = false
        this.$router.push({ name: 'StudentIndex' })
      }).catch(() => {
        alert('Hubo un error al crear tu registro')
      })
    },
    updateStudent: function () {
      if (this.$refs.form.validate() !== true) {
        this.toggleSubmitError()
        return
      }
      this.user.password = null
      this.user.blood_type = this.user.blood_type == "No especifcado" ? null : this.user.blood_type
      // remove proteries from this.user
      delete this.user['is_rejected_insurance_file'];
      delete this.user['is_rejected_insurance'];
      delete this.user['insurance_sent_date'];
      delete this.user['insurance_end_date'];
      this.$store.dispatch('Student/update', JSON.parse(JSON.stringify(this.user))).then(() => {
        alert('Se ha editado el registro con éxito')
        this.pendingChanges = false
        this.$router.push({ name: 'StudentIndex' })
      }).catch(() => {
        alert('Hubo un error al editar tu registro')
      })
    },
    toggleCreatedSuccess: function () {
      if (this.createdSuccess === false) {
        this.createdSuccess = true,
          setTimeout(this.toggleCreatedSuccess, 2000)
      }
      else {
        this.createdSuccess = false
      }
    },
    toggleSubmitError: function () {
      if (this.submitError === false) {
        setTimeout(() => {
          const el = document.querySelector(".v-messages.error--text").parentElement.parentElement
          el.scrollIntoView()
        }, 100)
        this.submitError = true,
          setTimeout(this.toggleSubmitError, 2000)
      }
      else {
        this.submitError = false
      }
    },
    toggleEdittedSuccess: function () {
      if (this.edittedSuccess === false) {
        this.edittedSuccess = true,
          setTimeout(this.toggleEdittedSuccess, 2000)
      }
      else {
        this.edittedSuccess = false
      }
    },
  },
  computed: {
    doyangSurrogations() {
      return this.$store.getters['DoyangSurrogation/doyangSurrogations']
    },
    totalPointsToAdd() {
      if (!this.user?.point_events) return null
      const pointsTournaments = this.user?.point_events?.event_totals ?? 0
      const pointsEvents = this.user?.point_events?.tournament_totals ?? 0
      const pointsMeetings = this.user?.point_events?.meeting_totals ?? 0
      return pointsEvents + pointsTournaments + pointsMeetings;
    },
    has_recommended() {
      return this.user?.has_recommended || []
    },
    monthlyPayments() {
      const paysMonth = this.$store.getters['Payment/payments'].filter(payment => {
        if (payment?.covers_month) return true
        if (payment?.is_canceled) return true
        return false
      })
      return paysMonth.map(payment => payment.receipt)
    },
    students() {
      return this.$store.getters['Student/students'].map((student) => {
        const doyang = this.doyangs.filter(doyang => doyang.id == student.doyang_id)[0]
        const degree = this.degrees.filter(degree => degree.id == student.degree_id)[0]
        student['degree'] = degree
        student['doyang'] = doyang
        student['name_with_degree'] = `${student['full_name']} (${degree?.name}, ${doyang?.name})`
        return student
      })
    },
    canSeeGeneralDirection() {
      return this.ENV?.permissions?.can_see_general_direction?.includes(parseInt(this?.authInfo?.user?.role_id))
    },
    availableBundles() {
      return this.user.available_bundles
    },
    isGeneralDirection() {
      return this.selectedDoyang.id == ENV.DIRECCIÓN_GENERAL_ID
    },
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    selectedDoyang() {
      if (!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined && (this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
        return {
          name: 'DIRECCIÓN GENERAL'
        }
      }
      return this.$store.getters['Auth/selectedDoyang']
    },
    doyangs() {
      return this.$store.getters['Doyang/doyangs'] ? this.$store.getters['Doyang/doyangs'] : []
    },
    doyangGroups() {
      return this.$store.getters['DoyangGroup/doyangGroups'] || []

    },
    degrees: function () {
      return this.$store.getters['Degree/degrees']
    },
    objectDefaultStudent: function () {
      return this.$store.getters['Student/student']
    },
    edadString() {
      const ageInYears = this.getAgeInYears(this.user.birth_date)
      return `${ageInYears} años`
    },
    personal() {
      if (this.doyangs && this.doyangs.length > 0 && this.user && this.user.doyang_id) {
        const selectedDoyang = this.doyangs.filter(doyang => doyang.id === this.user.doyang_id)[0];
        console.log('selectedDoyang', selectedDoyang)
        let personal = selectedDoyang ? selectedDoyang.personal : []
        personal.push(selectedDoyang.responsible)
        return personal
      }
      return []
    },
    allProfessors() {
      return this.$store.getters['Professor/professors']
    }
  },
  watch: {
    'user.enrollment_date': function () {
      if (!this.$route.params.id) {
        this.user.start_tkd_date = this.user.enrollment_date
      }
    },
    'user.birth_date': function () {
      if (!this.user.birth_date) return null
      const date = this.user.birth_date
      const [year, month, day] = date.split('-')
      this.parsedDate = `${day.padStart(2, '0')}/${ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`
    },
    'user.last_aproved_exam_date': function () {
      if (!this.user.last_aproved_exam_date) return null
      const date = this.user.last_aproved_exam_date
      const [year, month, day] = date.split('-')
      this.parsedLastExamDate = `${day.padStart(2, '0')}/${ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`
    },
    search: debounce(function () {
      this.$store.dispatch('Degree/index', this.search ? `?name=${this.search}` : '')
    }, 500),
    objectDefaultStudent: function () {
      this.user = this.objectDefaultStudent
      this.user.honorific_title_id = this.user.honorific_title_id ? Number(this.user.honorific_title_id) : this.user.honorific_title_id
      this.user.user_responsible_id = this.user.user_responsible_id ? Number(this.user.user_responsible_id) : this.user.user_responsible_id
      this.user.role_id = this.objectDefaultStudent.role_id ? Number(this.objectDefaultStudent.role_id) : this.objectDefaultStudent.role_id
      this.user.degree_id = this.objectDefaultStudent.degree_id ? Number(this.objectDefaultStudent.degree_id) : this.objectDefaultStudent.degree_id
      this.user.degree_id = this.user.degree_id ? this.user.degree_id : 1
      this.user.sex = this.objectDefaultStudent.sex ? Number(this.objectDefaultStudent.sex) : this.objectDefaultStudent.sex
      this.user.active = this.objectDefaultStudent.active != "0"
      this.user.has_verified_address = this.objectDefaultStudent.has_verified_address != "0"
      this.user.is_scholarshiped = this.objectDefaultStudent.is_scholarshiped != "0"
    }
  },
  data() {
    return {
      ENV,
      isProspect: false,
      childPickerEdit: {
        name: null,
        telephone: null,
        kinship: null
      },
      showPointsDialog: null,
      editEnrollment: {
        enrollment_date: null,
        disenroll_date: null,
      },
      editEnrollmentId: null,
      showDisenrollmentDatePicker: false,
      pendingChanges: true,
      originalEnrollment: false,
      parsedDate: null,
      dialogStudentExist: false,
      existingStudents: [],
      parsedLastExamDate: null,
      roleArray: [
        { name: 'Alumno', id: ENV.ROLE_STUDENT_ID },
        { name: 'Orientación y enlace', id: ENV.ROLE_LINK_AND_ORIENTATION },
        { name: 'Kiosa dependiente', id: ENV.ROLE_DEPENDANT_KYOSA_ID },
        { name: 'Kiosa de club', id: ENV.ROLE_CLUB_KYOSA_ID },
        { name: 'Kiosa de doyang', id: ENV.ROLE_DOYANG_KYOSA_ID },
        { name: 'Relaciones públicas', id: ENV.ROLE_PUBLIC_RELATIONS },
        { name: 'Profesor', id: ENV.ROLE_PROFESSOR_ID },
        { name: 'Profesor certificado', id: ENV.ROLE_CERTIFIED_PROFESSOR_ID },
        { name: 'Directora administrativa', id: ENV.ROLE_ADMINISTRATIVE_DIRECTOR },
        { name: 'Director técnico', id: ENV.ROLE_TECHNICAL_DIRECTOR },
        { name: 'Programador', id: ENV.ROLE_PROGRAMMER },
        { name: 'Director general', id: ENV.ROLE_GENERAL_DIRECTOR },
      ],
      datePickerMenu: false,
      lastAprovedExamMenu: null,
      enrollmentMenu: false,
      lastreenrollmentMenu: false,
      lastdropoutOtherMenu: false,
      lastDropoutMenu: false,
      groupRules: [
        v => !!v || 'El campo no puede estar vacio',
      ],
      nameRules: [
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      startPaymentsMonth: [
        v => !!v || 'El campo no puede estar vacio',
      ],
      inscriptionDateRules: [
        v => v != undefined || 'El campo no puede estar vacio',
        v => (v != undefined && v.length > 0) || 'El campo no puede estar vacío',
      ],
      birthDateRules: [
        v => !!v || 'El campo no puede estar vacio',
      ],
      emailRules: [
        // v => !!v || 'Debe ingresar un correo electrónico',
        // v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El email tiene que ser valido',
        // v => (v && v.length <= 100) || 'Debe ser menos de 100 caracteres',
      ],
      phoneRules: [
        // () => ((this.user.cellphone && this.user.cellphone.length > 0) || (this.user.phone1 && this.user.phone1.length > 0) || (this.user.phone2 && this.user.phone2.length > 0)) || "Debes introducir al menos un telefono"
      ],
      degreeRules: [
        v => !!v || "Selecciona la cinta"
      ],
      genderRules: [
        v => !!v || "Selecciona un sexo"
      ],
      jobRules: [
        v => !!v || "Escribe una ocupación",
        v => (v && v.length > 1) || 'Escribe una ocupación'
      ],
      // schoolRules: [
      //   v => !!v || "El alumno debe tener una escuela"
      // ],
      addressRules: [
        v => !!v || "El alumno debe tener una dirección"
      ],
      inscriptionRules: [
        v => !!v || "El alumno debe tener una fecha de inscripción"
      ],
      createdSuccess: false,
      submitError: false,
      edittedSuccess: false,
      isLoadingDegree: false,
      search: null,
      tab: null,
      tabPoints: null,
      tab_items: [
        { id: 0, text: "DATOS DEL ALUMNO" },
        { id: 1, text: "DATOS DE LOS PADRES" },
        { id: 2, text: "OTROS DATOS" }
      ],
      sex_options: [
        { id: 1, text: "HOMBRE" },
        { id: 2, text: "MUJER" },
      ],
      civil_options: ENV.CIVIL_STATES,
      blood_options: ENV.BLOOD_TYPES,
      user: {
        name: '',
        doyang_surrogation_id: null,
        has_verified_address: false,
        child_pickers: [],
        start_assistance_date: null,
        extra_absences: false,
        father_cellphone: null,
        mother_cellphone: null,
        birth_date: null,
        last_name: '',
        role_id: null,
        second_last_name: '',
        email: '',
        address: '',
        cellphone: '',
        phone1: '',
        phone2: '',
        degree_id: '',
        health_info: null,
        key: "",
        active: false,
        control_number: "",
        weight_kg: 0.00,
        height_m: 0.00,
        last_aproved_exam_date: null,
        school_id: null,
        sex: null,
        civil_state: null,
        enrollment_date: "",
        last_dropout_date: "",
        blood_type: null,
        job: "",
        job_address: "",
        job_description: "",
        job_title: "",
        job_boss: "",
        father_name: "",
        father_job: "",
        father_job_title: "",
        father_job_boss: "",
        father_job_company: "",
        mother_name: "",
        mother_job: "",
        mother_job_title: "",
        mother_job_boss: "",
        mother_job_company: "",
        start_tkd_date: null,
        start_payments_date: null,
        recomended_by: [
          { full_name: "", id: 0 }
        ],
        enrollment_bundle: {
          name: ""
        },
        doyang_group: {
          id: null
        },
        set_points: 0,
        last_reeenrollment_date: ""
      },
      other: {
      }
    }
  }
}
</script>

<style>
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<template>
  <v-container>
    <v-dialog v-model="showPointsDialog">
      <template v-slot:default="">
        <v-card>
          <v-toolbar color="green lighten-2" dark>
            <v-toolbar-title>Puntajes</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="pt-10">
            <v-tabs v-model="tabPoints">
              <v-tab>ASISTIÓ</v-tab>
              <v-tab>NO ASISTIÓ</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabPoints">
              <v-tab-item>
                <h3>Eventos</h3>
                <ul v-if="user.point_events">
                  <li v-for="pointEvent in user.point_events.events" :key="`${pointEvent.name}${pointEvent.id}`">
                    {{ pointEvent.name }} ({{ pointEvent.parsed_date }}):
                    <span v-if="pointEvent.result">{{ pointEvent.result ? pointEvent.result.name : '' }}:
                      {{ pointEvent.result ? pointEvent.result.points : '' }} pts</span>
                    <span v-else>No se ha asignado resultado</span>
                  </li>
                </ul>
                <h3>Torneos</h3>
                <ul v-if="user.point_events">
                  <li v-for="pointEvent in user.point_events.tournaments" :key="`${pointEvent.name}${pointEvent.id}`">
                    {{ pointEvent.name }} ({{ pointEvent.parsed_date }}):
                    <span v-if="pointEvent.result">{{ pointEvent.result ? pointEvent.result.name : '' }}</span>
                    <span v-else>No se ha asignado resultado</span>
                  </li>
                </ul>
                <h3>Juntas</h3>
                <ul v-if="user.point_events">
                  <li v-for="pointEvent in user.point_events.meetings" :key="`${pointEvent.name}`">
                    {{ pointEvent.name }} ({{ pointEvent.parsed_date }}):
                    <span v-if="pointEvent.result">{{ pointEvent.result ? pointEvent.result.name : '' }}</span>
                    <span v-else>No se ha asignado resultado</span>
                  </li>
                </ul>
              </v-tab-item>
              <v-tab-item>
                <h3>Eventos</h3>
                <ul v-if="user.non_assisted_events">
                  <li v-for="pointEvent in user.non_assisted_events.events" :key="`${pointEvent.name}${pointEvent.id}`">
                    {{ pointEvent.name }} ({{ pointEvent.parsed_date }}):
                    <span v-if="pointEvent.result">{{ pointEvent.result ? pointEvent.result.name : '' }}, Puntos:
                      {{ pointEvent.result ? pointEvent.result.points : '' }}</span>
                    <span v-else>No se ha asignado resultado</span>
                  </li>
                </ul>
                <h3>Torneos</h3>
                <ul v-if="user.non_assisted_events">
                  <li v-for="pointEvent in user.non_assisted_events.tournaments"
                    :key="`${pointEvent.name}${pointEvent.id}`">
                    {{ pointEvent.name }} ({{ pointEvent.parsed_date }}):
                    <span v-if="pointEvent.result">{{ pointEvent.result ? pointEvent.result.name : '' }}, Puntos:
                      {{ pointEvent.result ? pointEvent.result.points : '' }}</span>
                    <span v-else>No se ha asignado resultado</span>
                  </li>
                </ul>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-btn class="btn green lighten-2 mb-10" dark @click="showPointsDialog = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="showDisenrollmentDatePicker">
      <template v-slot:default="">
        <v-card>
          <v-toolbar color="green lighten-2" dark>
            <v-toolbar-title>Fecha de Alta</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <DMYDateField :label="`Fecha de alta`" v-model="editEnrollment.enrollment_date" />
            <DMYDateField :label="`Fecha de baja`" v-model="editEnrollment.disenroll_date" />
          </v-card-text>
          <v-card-footer>
            <v-btn class="btn mb-10 mr-10" @click="showDisenrollmentDatePicker = false">Cancelar</v-btn>
            <v-btn class="btn green lighten-2 mb-10" @click="udpateEnrollment()" dark>Aceptar</v-btn>
          </v-card-footer>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" max-width="1000" v-model="dialogStudentExist">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="green lighten-2" dark>Coincidencias</v-toolbar>
          <v-card-text>
            <div class="text-h6 pa-12">Puede que este alumno exista. ¿Lo reconoces entre alguno de los siguientes?
            </div>
            <v-container>
              <v-row>
                <v-col class="text-left">
                  <ul>
                    <li v-for="eStudent in existingStudents" :key="eStudent.id">{{ eStudent.full_name }} -
                      {{ eStudent.doyang ? eStudent.doyang.name : 'SIN DOYANG' }} <v-btn class="blue lighten-2 " small
                        dark>Transferir a {{ selectedDoyang.name }}</v-btn></li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text class="grey lighten 2" dark @click="createNewStudent($event, true)">No lo reconozco
              (crear)</v-btn>
            <v-btn text class="green lighten-2" @click="dialog.value = false">Lo reconozco (no crear)</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row>
      <v-col cols=12 v-if="this.title.length == 0">
        <h2 v-if="!this.$route.params.id">CREAR ALUMNO</h2>
        <h2 v-else>EDITAR ALUMNO</h2>
        <h2 v-if="this.user.active != 1">(DADO DE BAJA)</h2>
      </v-col>
      <v-col cols=12 v-else>
        <h2>{{ this.title }}</h2>
      </v-col>
    </v-row>
    <v-form ref="form">
      <v-tabs background-color="blue lighten-1" v-model="tab">
        <v-tab class="white--text" v-for="item in tab_items" :key="item.text">
          {{ item.text }}
        </v-tab>
        <v-tab-item class="text-left pa-4">
          <v-row>
            <v-col cols=12>
              <h2 v-if="!this.$route.params.id">CREAR ALUMNO</h2>
              <h2 v-else>EDITAR ALUMNO</h2>
              <h2 v-if="this.user.active != 1">(DADO DE BAJA)</h2>
            </v-col>
            <v-col cols=6 md=6>
              Promoción
              <v-select :items="availableBundles" v-model="user.doyang_bundle_id" item-value="id" item-text="name"
                outlined placeholder="Promoción"></v-select>
            </v-col>
            <v-col cols=6>
              <v-autocomplete outlined label="Multinivel (Eventos)" chips :items="doyangSurrogations"
                :disabled="!isGeneralDirection" v-model="user.doyang_surrogation_id" item-text="name"
                item-value="id"></v-autocomplete>
            </v-col>
            <v-col cols=6>
              <v-checkbox v-model="user.is_scholarshiped" label="Becado"
                :disabled="!isGeneralDirection && user.active == 1"></v-checkbox>
            </v-col>
            <v-col cols=6>
              <v-checkbox v-model="user.extra_absences" label="Tiene faltas de adulto"
                :disabled="!isGeneralDirection && user.active == 1"></v-checkbox>
            </v-col>
            <v-col cols=6 md=6>
              <v-checkbox v-model="user.active" label="Activo" @click.prevent="activate"
                :disabled="!isGeneralDirection && user.active == 0"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="isProspect" label="Prospecto"></v-checkbox>
            </v-col>
            <v-col cols=6 md=6>
              <v-text-field v-if="!user.active" :label="'Inactivo desde'" :value="user.disenroll_date" outlined
                disabled></v-text-field>
            </v-col>
            <v-col cols=6 md=6>
              <caps-v-text-field v-model="user.last_covered_month_parsed" :disabled="true"
                label="Último mes cubierto"></caps-v-text-field>
            </v-col>
            <v-col cols=6 md=6>
              <v-checkbox v-model="user.revalidating" label="Revalida grado en el siguiente examen"></v-checkbox>
            </v-col>
            <v-col cols=12 md=3>
              <v-select outlined label="Categoría"
                :disabled="!this.ENV.permissions.can_see_general_direction.includes(parseInt(this.authInfo.user.role_id))"
                v-model="user.role_id" :items="roleArray" item-text="name" item-value="id">
              </v-select>
            </v-col>
            <v-col cols=6 md=3>
              <DMYDateField :disabled="originalEnrollment && !canSeeGeneralDirection" :rules="inscriptionDateRules"
                :label="`Fecha de inscripción`" v-model="user.enrollment_date" />
            </v-col>
            <v-col cols=12 md=4>
              <CapsVTextField v-model="user.name" outlined label="Nombre(s)" :rules="nameRules"></CapsVTextField>
            </v-col>
            <v-col cols=12 md=4>
              <CapsVTextField v-model="user.last_name" outlined label="Apellido" :rules="nameRules"></CapsVTextField>
            </v-col>
            <v-col cols=12 md=4>
              <CapsVTextField v-model="user.second_last_name" outlined label="Segundo Apellido"></CapsVTextField>
            </v-col>
            <v-col cols=12 md=4>
              <v-autocomplete chips v-model="user.user_responsible_id" :items="allProfessors" outlined color="white"
                hide-no-data item-text="full_name_honorific" item-value="id" label="Profesor Responsable"
                placeholder="Profesor Responsable"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4>
              <v-select label="Doyang"
                :disabled="!this.ENV.permissions.can_see_general_direction.includes(parseInt(this.authInfo.user.role_id))"
                chips :items="doyangs" v-model="user.doyang_id" outlined item-text="name" item-value="id"
                @change="reloadGroups"></v-select>
            </v-col>
            <v-col cols=4 v-if="user && user.doyang_group">
              <v-select label="Grupo" :disabled="!this.user.active && this.user.id" chips :items="doyangGroups"
                v-model="user.doyang_group.id" outlined item-text="name" item-value="id"></v-select>
              <!-- <v-btn
                small
                class="mr-2"
                @click="goToDoyangGroup">
                    <v-icon>mdi-open-in-new</v-icon>
                </v-btn> -->
            </v-col>
            <v-col cols=12 md=4>
              <DMYDateField :label="`Fecha de nacimiento`" :rules="birthDateRules" v-model="user.birth_date" />
              <v-text-field :value="edadString" disabled outlined></v-text-field>
            </v-col>
            <v-col cols=12 md=4>
              <v-autocomplete chips :rules="degreeRules" v-model="user.degree_id" :items="degrees"
                :loading="isLoadingDegree" :search-input.sync="search" outlined color="white" hide-no-data hide-selected
                item-text="name" item-value="id" label="Cinta" placeholder="Cinta"></v-autocomplete>
              <CapsVTextField :disabled="!isGeneralDirection" type="number"
                v-if="user && user.degree && user.degree.has_partials" label="Parciales"
                v-model="user.number_of_partials">
              </CapsVTextField>
            </v-col>
            <v-col cols=12 md=6>
              <v-text-field v-model="user.email" type="email" :rules="emailRules" outlined label="Email"></v-text-field>
            </v-col>
            <v-col cols=12 md=6>
              <CapsVTextField v-model="user.address" outlined label="Calle y número" :rules="addressRules">
              </CapsVTextField>
              <v-checkbox label="Dirección verificada" v-model="user.has_verified_address"></v-checkbox>
            </v-col>
            <v-col cols=12 md=3>
              <CapsVTextField v-model="user.neighborhood" outlined label="Colonia" :rules="addressRules">
              </CapsVTextField>
            </v-col>
            <v-col cols=12 md=3>
              <CapsVTextField v-model="user.city" outlined label="Ciudad" :rules="addressRules"></CapsVTextField>
            </v-col>
            <v-col cols=12 md=6>
              <CapsVTextField v-model="user.address_2" outlined label="Calle y número (Segunda dirección)">
              </CapsVTextField>
            </v-col>
            <v-col cols=12 md=3>
              <CapsVTextField v-model="user.neighborhood_2" outlined label="Colonia (Segunda dirección)">
              </CapsVTextField>
            </v-col>
            <v-col cols=12 md=3>
              <CapsVTextField v-model="user.city_2" outlined label="Ciudad (Segunda dirección)"></CapsVTextField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 md=4>
              <CapsVTextField v-model="user.cellphone" outlined label="Celular" :rules="phoneRules"></CapsVTextField>
            </v-col>
            <v-col cols=12 md=4>
              <CapsVTextField v-model="user.phone1" outlined label="Telefono 1" :rules="phoneRules"></CapsVTextField>
            </v-col>
            <v-col cols=12 md=4>
              <CapsVTextField v-model="user.phone2" outlined label="Telefono 2" :rules="phoneRules"></CapsVTextField>
            </v-col>
            <v-col cols=12 md=12>
              <CapsVTextArea v-model="user.health_info" outlined label="Enfermedades o condiciones"></CapsVTextArea>
            </v-col>
          </v-row>
          <CapsVTextField v-model="user.control_number" outlined label="Matricula" :disabled="true"></CapsVTextField>
          <CapsVTextField v-model="user.weight_kg" outlined label="Peso (kg)" type="number"></CapsVTextField>
          <CapsVTextField v-model="user.height_m" outlined label="Estatura (cm)" type="number"></CapsVTextField>
          <CapsVTextField v-model="user.school" outlined label="Escuela (académica)" :disabled="false">
          </CapsVTextField>
          <v-autocomplete v-model="user.sex" :items="sex_options" item-value="id" item-text="text" label="Sexo"
            :rules="genderRules" outlined chips></v-autocomplete>
          <v-autocomplete v-model="user.civil_state" :items="civil_options" label="Estado Civil" chips
            outlined></v-autocomplete>
          <v-select v-model="user.blood_type" :items="blood_options" item-value="text" item-text="text"
            label="Tipo de sangre" chips outlined></v-select>
          <CapsVTextField v-model="user.job" outlined label="Trabajo"></CapsVTextField>
          <CapsVTextField v-model="user.job_address" outlined label="Dirección del empleo"></CapsVTextField>
          <CapsVTextField v-model="user.job_description" outlined label="Ocupacion" :rules="jobRules">
          </CapsVTextField>
          <CapsVTextField v-model="user.job_title" outlined label="Puesto"></CapsVTextField>
          <CapsVTextField v-model="user.job_boss" outlined label="Responsable"></CapsVTextField>
        </v-tab-item>
        <v-tab-item>
          <h3>Padre</h3>
          <CapsVTextField v-model="user.father_name" outlined label="Nombre del padre o tutor">
          </CapsVTextField>
          <CapsVTextField v-model="user.father_cellphone" outlined label="Teléfono del padre o tutor">
          </CapsVTextField>
          <CapsVTextField v-model="user.father_job" outlined label="Ocupacion del padre"></CapsVTextField>
          <CapsVTextField v-model="user.father_job_title" outlined label="Puesto del padre"></CapsVTextField>
          <CapsVTextField v-model="user.father_job_company" outlined label="Empresa donde labora el padre">
          </CapsVTextField>
          <h3>Madre</h3>
          <CapsVTextField v-model="user.mother_name" outlined label="Nombre del madre o tutora">
          </CapsVTextField>
          <CapsVTextField v-model="user.mother_cellphone" outlined label="Telefono de la madre o tutora">
          </CapsVTextField>
          <CapsVTextField v-model="user.mother_job" outlined label="Ocupacion del Madre"></CapsVTextField>
          <CapsVTextField v-model="user.mother_job_title" outlined label="Puesto del Madre"></CapsVTextField>
          <CapsVTextField v-model="user.mother_job_company" outlined label="Empresa donde labora la madre">
          </CapsVTextField>
          <h3>Personas autorizadas para recoger</h3>
          <v-row>
            <v-col>
              <CapsVTextField v-model="childPickerEdit.name" outlined label="Nombre"></CapsVTextField>
              <CapsVTextField v-model="childPickerEdit.telephone" outlined label="Teléfono"></CapsVTextField>
              <CapsVTextField v-model="childPickerEdit.kinship" outlined label="Parentesco"></CapsVTextField>
              <v-btn class="green lighten-2" dark @click="addChildPickers">Agregar</v-btn>
            </v-col>
            <v-col>
              <v-data-table
                :headers="[{ text: 'Nombre', value: 'name' }, { text: 'Parentesco', value: 'kinship' }, { text: 'Acciones', value: 'actions' }]"
                :items="user.child_pickers" :items-per-page="5" class="elevation-1">
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteChildPicker(item.name)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="pt-5">
          <DMYDateField :label="`Fecha del último examen aprobado`" v-model="user.last_aproved_exam_date" />
          <DMYDateField v-model="user.start_tkd_date" :label="'Fecha de inicio en Taekwondo'" />
          <v-row>
            <v-col cols=6 md=3>
              <CapsVTextField type="number" label="Puntaje Inicial" v-model="user.starting_curriculum_points" outlined>
              </CapsVTextField>
              <span><strong>{{ totalPointsToAdd || 0 }}</strong></span> puntos por eventos
              <span><strong>{{ (+totalPointsToAdd) + (+user.starting_curriculum_points) }}</strong></span> Total
            </v-col>
            <v-col cols=6 md=3>
              Puntos requeridos para el siguiente examen: {{ (user && user.required_points_for_degree) ?
      user.required_points_for_degree : 0 }}
            </v-col>
            <v-col cols=6 md=3>
              <v-btn class="green lighten-2" dark @click="showPointsDialog = true">Mostrar historial</v-btn>
              <v-btn class="green lighten-2" dark @click="downloadCurriculumCsv">Descargar historial</v-btn>
            </v-col>
          </v-row>
          <h3>Recomendado por</h3>
          <v-autocomplete chips v-model="user.recomended_by" :items="students" outlined color="white" hide-no-data
            item-text="name_with_degree" item-value="id" label="Recomendado por"
            placeholder="Recomendado por"></v-autocomplete>
          <h3>Recomendó a</h3>
          <v-data-table :items="has_recommended" :headers="[
      { text: 'Nombre', value: 'full_name' }
    ]"></v-data-table>
          <h3>{{ has_recommended.length }} recomendados, {{ has_recommended.length * 25 }}% de descuento</h3>
          <v-spacer></v-spacer>
          <br>
          <CapsVTextField v-if="user.enrollment_bundle" v-model="user.enrollment_bundle.name"
            label="Paquete de inscripcion" outlined></CapsVTextField>
          <CapsVTextField v-if="user.enrollment_bundle" v-model="user.set_points" type="number" label="Puntos iniciales"
            outlined></CapsVTextField>
          <h3>Historial de reinscripciones</h3>
          <v-data-table :items="user.enrollments" :headers="[
      { text: 'Fecha de inicio', value: 'enrollment_date_parsed' },
      { text: 'Fecha de fin', value: 'disenroll_date' },
      { text: 'Acciones', value: 'actions' },
    ]">
            <template v-slot:item.enrollment_date_parsed="{ item }">
              <div>
                {{ item.enrollment_date_parsed }}
              </div>
            </template>
            <template v-slot:item.disenroll_date="{ item }">
              <div>
                {{ item.disenroll_date }}
              </div>
            </template>
            <template v-if="canSeeGeneralDirection" v-slot:item.actions="{ item }">
              <div>
                <v-icon @click="openDisenrollmentDatePicker(item.id)">mdi-pencil</v-icon>
                <v-icon @click="deleteEnrollment(item.id)">mdi-delete</v-icon>
              </div>
            </template>
          </v-data-table>
          <v-row>
            <v-col>
              <MYDateField :rules="startPaymentsMonth" :label="'Primer mes que se cobra'"
                v-model="user.start_payments_date"></MYDateField>
            </v-col>
            <v-col>
              <DMYDateField :label="`Fecha de inicio de asistencia`" v-model="user.start_assistance_date" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>RECIBOS DE MENSUALIDAD</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table :items="monthlyPayments" :headers="[
      { text: 'Fecha', value: 'parsed_created_at' },
      { text: 'Pagos', value: 'payments' },
      { text: 'Acciones', value: 'actions' }
    ]">
                <template v-slot:[`item.payments`]="{ item }">
                  <ul>
                    <li v-for="payment in item.payments" :key="payment.id">
                      {{ payment.title }} {{ payment.is_canceled == 1 ? '(CANCELADO)' : '' }}
                    </li>
                  </ul>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon md class="" @click="printReceipt(item.id)">
                    mdi-printer
                  </v-icon>
                  <v-icon md class="" @click="cancelReceipt(item.id)">
                    mdi-cancel
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-form>
    <v-btn primary @click="createNewStudent" v-if="!this.$route.params.id" class="blue ligthten-2" dark>Agregar</v-btn>
    <v-btn primary @click="updateStudent" v-else color="orange lighten-2" dark>Editar</v-btn>
    <v-alert class="mt-5" type="success" v-model="createdSuccess">El registro se ha creado con exito</v-alert>
    <v-alert class="mt-5" type="error" v-model="submitError">Hubo un error al guardar los datos, intentalo mas
      tarde</v-alert>
    <v-alert class="mt-5" type="success" v-model="edittedSuccess">El registro se ha editado con exito</v-alert>
  </v-container>
</template>
