import Vue from 'vue'
import Vuex from 'vuex'
import Student from './student'
import Professor from './professor'
import Degree from './degree'
import Doyang from './doyang'
import DoyangGroup from './doyang_group'
import DoyangType from './doyang_type'
import Exam from './exam'
import School from './school'
import Product from './product'
import ExamGroup from './exam_group'
import PaymentConcept from './payment_concept'
import Discount from './discount'
import Event from './event'
import ExamCondition from './exam_condition'
import ExamResult from './exam_result'
import TournamentResult from './tournament_result'
import Outflow from './outflow'
import Permission from './permission'
import Privilege from './privilege'
import Role from './role'
import EventGroup from './event_group'
import EventResult from './event_result'
import Payment from './payment'
import Tournament from './tournament'
import TournamentGroup from './tournament_group'
import Attendance from './attendance'
import HonorificTitle from './honorific_title'
import Sinodal from './sinodal'
import Venue from './venue'
import State from './state'
import City from './city'
import Inventory from './inventory'
import DoyangCategory from './doyang_category'
import DoyangBundle from './doyang_bundle'
import DoyangCategoryRequisite from './doyang_category_requisite'
import Auth from './auth'
import MonthlyDuty from './montly_duty'
import Requirement from './requirement'
import ProductVariant from './product_variant'
import ProductSize from './product_size'
import InventoryMove from './inventory_move'
import DoyangSurrogation from './doyang_surrogation'
import DoyangSurrogationEvent from './doyang_surrogation_event'
import DoyangSurrogationExam from './doyang_surrogation_exam'
import DoyangSurrogationBus from './doyang_surrogation_bus'
import DoyangSurrogationTournament from './doyang_surrogation_tournament'
import Receipt from './receipt'
import OtherExamRequisite from './other_exam_requisiste'
import MultilevelDiscountType from './multilevel_discount_type'
import Configuration from './configuration'
import EventComission from './event_comission'
import Assistance from './assistance'
import Enrollment from './enrollment'
import Time from './time'
import Global from './global'
import Holiday from './holiday'
import Bimester from './bimester'
import Revision from './revision'
import Insurance from './insurance'
import GeneralFileResource from './general_file_resource'
import ProgrammerResources from './programmer_resources'
import ExamBlock from './exam_block'
import TournamentCategory from './tournament_category'
import TournamentAgeCategory from './tournament_age_category'
import TournamentDegreeCategory from './tournament_degree_category'
import TournamentWeightCategory from './tournament_weight_category'
import TournamentAgeGroup from './tournament_age_group'
import TournamentArea from './tournament_area'
import Expense from './expense'
import ParentEvent from './parent_event'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth,
    Holiday,
    Enrollment,
    Student,
    Degree,
    Doyang,
    Exam,
    School,
    Product,
    ExamGroup,
    PaymentConcept,
    Discount,
    DoyangGroup,
    Event,
    ExamCondition,
    ExamResult,
    Outflow,
    Permission,
    Privilege,
    Role,
    EventResult,
    Professor,
    DoyangType,
    Payment,
    Tournament,
    TournamentGroup,
    Attendance,
    HonorificTitle,
    Sinodal,
    Venue,
    State,
    EventGroup,
    City,
    Inventory,
    DoyangCategory,
    DoyangBundle,
    DoyangCategoryRequisite,
    MonthlyDuty,
    Requirement,
    ProductVariant,
    ProductSize,
    InventoryMove,
    DoyangSurrogation,
    DoyangSurrogationEvent,
    DoyangSurrogationExam,
    DoyangSurrogationTournament,
    Receipt,
    OtherExamRequisite,
    MultilevelDiscountType,
    Configuration,
    EventComission,
    Assistance,
    TournamentResult,
    Time,
    Global,
    Bimester,
    Revision,
    Insurance,
    GeneralFileResource,
    ProgrammerResources,
    ExamBlock,
    DoyangSurrogationBus,
    TournamentCategory,
    TournamentAgeCategory,
    TournamentDegreeCategory,
    TournamentWeightCategory,
    TournamentAgeGroup,
    TournamentArea,
    Expense,
    ParentEvent
  }
})
