<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>Gastos</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <CapsVTextField v-model="expense.name" label="Nombre"></CapsVTextField>
            </v-col>
            <v-col>
                <CapsVTextField v-model="expense.amount" type="number" label="Monto"></CapsVTextField>
            </v-col>
            <v-col>
                <DMYDateField v-model="expense.date" label="Fecha"></DMYDateField>
            </v-col>
            <v-col>
                <input type="checkbox" v-model="expense.is_fixed_expense"> Es gasto fijo
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn @click="saveExpense">Guardar</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CapsVTextField from '../globals/CapsVTextField.vue';
import DMYDateField from '../globals/DMYDateField.vue';

export default {
    name: 'ExpenseCreate',
    components: {
        CapsVTextField,
        DMYDateField
    },
    mounted() {
        this.expense.id = this.$route.params.id
        if (this.expense.id) {
            this.$store.dispatch('Expense/show', this.expense.id).then(response => {
                this.expense = response
            })
        }
    },
    methods: {
        saveExpense() {
            this.expense.doyang_id = this.selectedDoyang.id
            if (this.expense.id)
                this.$store.dispatch('Expense/update', this.expense).then(() => {
                    this.$router.push({ name: 'ExpenseIndex' })
                });
            else {
                this.$store.dispatch('Expense/create', this.expense).then(() => {
                    this.$router.push({ name: 'ExpenseIndex' })
                });
            }
        }
    },
    computed: {
        selectedDoyang() {
            if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && (this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
                return {
                    name: 'DIRECCIÓN GENERAL'
                }
            } else if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && !(this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
                return {
                    name: 'SIN DOYANG SELECCIONADO'
                }
            }
            return this.$store.getters['Auth/selectedDoyang']
        },
    },
    data() {
        return {
            expense: {
                id: null,
                name: null,
                doyang_id: null,
                amount: null,
                is_fixed_expense: false
            }
        }
    }
}
</script>

<style scoped></style>