<template>
  <v-row>
    <v-col>
      <v-text-field outlined v-model="search" placeholder="Búsqueda"></v-text-field>
      <v-btn
        v-if="hasPermission(permissionRoot, 'create') || ENV.permissions.can_see_general_direction.includes(parseInt(authInfo.user.role_id))"
        tile color="primary" :to="{ name: defaultRouteCreate }">
        <v-icon left>
          mdi-pencil
        </v-icon>
        Añadir
      </v-btn>
      <v-data-table :search="search" :headers="headers" :items="modelItems" :items-per-page="itemsPerPage"
        class="elevation-1" :custom-filter="filterOnRequiredFields">
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="goToProspects(item.id)">
            mdi-account
          </v-icon>
          <v-icon
            v-if="hasPermission(permissionRoot, 'update') || ENV.permissions.can_see_general_direction.includes(parseInt(authInfo.user.role_id))"
            small class="mr-2" @click="goToEditItem(item.id)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2"
            v-if="hasPermission(permissionRoot, 'delete') || ENV.permissions.can_see_general_direction.includes(parseInt(authInfo.user.role_id))"
            @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ENV from '../../../env.js'
export default {
  methods: {
    hasPermission(name, action = false) {
      const names = name.split(",")
      if (names.length > 1) {
        let hasPermission = false
        names.forEach(name => {
          if (hasPermission) return;
          hasPermission = this.hasPermission(name, action)
        })
        return hasPermission
      }
      if (!name) return false;
      const permissions = this.authInfo?.user?.permissions
      if (!permissions) return false
      const singlePermissionkey = Object.keys(permissions).filter(key => {
        return permissions[key].name === name
      })?.[0]
      const singlePermission = permissions?.[singlePermissionkey]
      if (!singlePermissionkey) return false
      if (!action && singlePermission) {
        return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
      }
      return singlePermission?.[action] == 1 || false
    },
    deleteItem(item) {
      item = item.id ? item.id : item
      this.$store.dispatch(`${this.storeModel}/delete`, item)
    },
    goToProspects(id) {
      this.$router.push({ name: this.defaultRouteProspects, params: { id } })
    },
    goToEditItem(id) {
      this.$router.push({ name: this.defaultRouteEdit, params: { id } })
    },
    filterOnRequiredFields(value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        item.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    },
  },
  mounted() {
    this.$store.dispatch(`${this.storeModel}/index`, `?orderBy[date]=desc&append=spanning_days&${this.filterThisYearOnly ? 'filterThisYearOnly=1' : ''}`)
  },
  data() {
    return {
      search: '',
      ENV,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Fecha', value: 'parsed_date' },
        { text: 'Sede', value: 'venue.name' },
        { text: 'Días', value: 'spanning_days' },
        { text: 'Localidad', value: 'venue.city.name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    }
  },
  watch: {
    filterThisYearOnly(value) {
      this.$store.dispatch(`${this.storeModel}/index`, `?orderBy[date]=desc&append=spanning_days&${this.filterThisYearOnly ? 'filterThisYearOnly=1' : ''}`)
    }
  },
  computed: {
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    modelItems: function () {
      return this.$store.getters[`${this.storeModel}/${this.storeModel.toLowerCase()}s`]
    },
    defaultRouteCreate: function () {
      return this.routeCreate.length > '' ? this.routeCreate : `${this.storeModel}Create`
    },
    defaultRouteEdit: function () {
      return this.routeEdit.length > '' ? this.routeEdit : `${this.storeModel}Edit`
    },
    defaultRouteProspects: function () {
      return this.routeProspects.length > '' ? this.routeProspects : `${this.storeModel}Prospects`
    }
  },
  props: {
    permissionRoot: String,
    filterThisYearOnly: { type: Boolean, default: true },
    routeCreate: { type: String, default: '' },
    routeEdit: { type: String, default: '' },
    routeProspects: { type: String, default: '' },
    storeModel: { type: String, default: '' },
    payloads: {
      type: Object,
      default: function () {
        return {
          indexPayload: '',
          showPayload: '',
          deletePayload: '',
          updatePayload: ''
        }
      }
    },
    itemsPerPage: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style></style>
