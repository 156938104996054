/* eslint-disable no-console */

import { register } from 'register-service-worker'

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }

  // Register the service worker with options to prevent caching
  navigator.serviceWorker.register('/service-worker.js', {
    updateViaCache: 'none',
    cacheName: updateCacheName(),
    headers: { 'Cache-Control': 'no-store' },
  });
// if (process.env.NODE_ENV === 'production') {
    function updateCacheName() {
        return 'v1.51.0'; // Update this value whenever you make changes to your PWA
    }
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      // new content clear cache so user gets the new version
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
      console.log("New content is downloading.");
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
// }
