<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="printGraphs">Imprimir todas las gráficas</v-btn>
        </v-col>
        <v-col ref="print-col-tournament-graphs" class="overlay" v-if="areGraphsPrinting">
            <v-row v-for="tournamentGraphInfo in tournamenGraphsByCategory" :key="tournamentGraphInfo.name">
                <v-col>
                    <v-row>
                        <v-col v-for="(graph) in tournamentGraphInfo.graphs" :key="graph.graph_number" cols=12>
                            <v-card class="printable-card">
                                <v-card-title class="card-title-text" v-if="graph">
                                    {{ tournamentGraphInfo.name }}
                                    {{ graph['tournament_category_id'] }}.{{ graph['graph_number'] }}
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-row>
                                                <UserBox :user="graph.users[4]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[5]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row class="mt-10">
                                                <UserBox :user="graph.users[6]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[7]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" class="level-2">
                                            <v-row>
                                                <UserBox :user="graph.users[2]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[3]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" class="level-1">
                                            <v-row>
                                                <UserBox :user="graph.users[1]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-for="tournamentGraphInfo in exhibitionTournamenGraphsByCategory" :key="tournamentGraphInfo.name">
                <v-col>
                    <v-row>
                        <v-col v-for="(graph) in tournamentGraphInfo.graphs" :key="graph.graph_number" cols=12>
                            <v-card class="printable-card">
                                <v-card-title class="card-title-text" v-if="graph">
                                    {{ tournamentGraphInfo.name }}
                                    {{ graph['tournament_category_id'] }}.{{ graph['graph_number'] }}
                                    Exhibición
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-row>
                                                <UserBox :user="graph.users[4]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[5]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row class="mt-10">
                                                <UserBox :user="graph.users[6]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[7]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" class="level-2">
                                            <v-row>
                                                <UserBox :user="graph.users[2]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[3]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" class="level-1">
                                            <v-row>
                                                <UserBox :user="graph.users[1]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-for="tournamentGraphInfo in aloneTournamenGraphsByCategory" :key="tournamentGraphInfo.name">
                <v-col>
                    <v-row>
                        <v-col v-for="(graph) in tournamentGraphInfo.graphs" :key="graph.graph_number" cols=12>
                            <v-card class="printable-card">
                                <v-card-title class="card-title-text" v-if="graph">
                                    {{ tournamentGraphInfo.name }}
                                    {{ graph['tournament_category_id'] }}.{{ graph['graph_number'] }}
                                    1er Lugar sin competir
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-row>
                                                <UserBox :user="graph.users[4]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[5]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row class="mt-10">
                                                <UserBox :user="graph.users[6]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[7]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" class="level-2">
                                            <v-row>
                                                <UserBox :user="graph.users[2]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                            <v-row>
                                                <UserBox :user="graph.users[3]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4" class="level-1">
                                            <v-row>
                                                <UserBox :user="graph.users[1]"
                                                    :graphCompetitionType="graphCompetitionType" />
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import UserBox from './UserBox.vue';

export default {
    name: 'PrintTournamentGraphs',
    props: {
        aloneTournamenGraphsByCategory: {
            type: Array,
            required: true,
        },
        exhibitionTournamenGraphsByCategory: {
            type: Array,
            required: true,
        },
        tournamenGraphsByCategory: {
            type: Array,
            required: true,
        },
        graphCompetitionType: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            areGraphsPrinting: false,
        };
    },
    methods: {
        printGraphs() {
            this.areGraphsPrinting = true;
            setTimeout(() => {
                window.print();
                this.areGraphsPrinting = false;
            }, 1000);
        },

    },
    mounted() {
        // Code to run when the component is mounted
    },
    components: {
        UserBox,
    },
};
</script>

<style scoped>
.card-title-text {
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}

.graph-user-text {
    font-size: 8pt;
    font-weight: 900;
    line-height: 0;
}

.printable-card {
    page-break-inside: avoid;
}

.overlay {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
}

.graph-node {
    font-size: 9pt;
    border-width: 2px;
    border-style: solid;
    height: 80px;
    text-align: left;
}

.level-2 {
    margin-top: 50px;
}

.level-1 {
    margin-top: 75px;
}
</style>
