import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    cities: [],
    city: {}
  },
  mutations: {
    index(state, payload) {
      state.cities = payload
    },
    show(state,payload) {
      state.city = payload
    }
  },
  actions: {
    index ({commit}) {
      return axios.get(`${ENV.API_URL}/api/cities`).then((res) => {
        commit('index', res.data)
        return (res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/cities/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/cities`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/cities/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    cities: state => {
      return state.cities
    },
    city: state => {
      return state.city
    }
  },
  modules: {
  }
}
