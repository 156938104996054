<template>
    <div>
        <v-dialog v-model="active">
            <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                    <a>{{ examName }}:</a>
                </span>
            </template>
            <v-card>
                <v-card-title>{{ examName }} | {{ renderDoyang.name }}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <span>
                                    <span v-if="exam_record.count == undefined">No hay torneos</span>
                                    <strong v-else>Tu record:</strong>
                                    {{ exam_record.count }}
                                    ({{ exam_record.name }})
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span>
                                    <strong>Ventas: </strong>{{ exam ? exam['count'] : 0 }}/{{ exam &&
            exam['goal'] || 0
                                    }} (meta <v-icon @click="establishGoal(renderDoyang.id, examName, 'exam')"
                                        v-if="canEditGoals && areGoalsModifiable">mdi-pencil</v-icon>)
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span style="color: blue; cursor: pointer;"
                                    @click="downloadExamListCsv(exam['id'], renderDoyang.id)">Descargar
                                    csv | </span>
                                <span style="margin-left:6px; color: blue; cursor: pointer;" @click="downloadExamHotelBusListExcel(
            (renderDoyang.next_exams_totals && exam ? exam['id'] : null)
            , renderDoyang.id)">Descargar excel de camión/hotel</span>

                            </v-col>
                        </v-row>
                    </v-container>
                    <v-data-table :headers="[
            { 'value': 'range', 'text': 'Rango' },
            { 'value': 'percentage', 'text': 'Porciento' },
            { 'value': 'price', 'text': 'Costo del examo' },
            { 'value': 'utility', 'text': 'Utilidad' },
            { 'value': 'minimum', 'text': 'Mínimo' },
            { 'value': 'maximum', 'text': 'Máximo' },
            { 'value': 'annual', 'text': 'Anual' },
        ]" v-if="exam && exam.show_table_in_dashboard" hide-default-footer :items="doyang_surrogation_exams">
                        <template v-slot:item.range="{ item }">
                            <div>
                                {{ item.min_inscriptions }}-{{ item.max_inscriptions }}
                            </div>
                        </template>
                        <template v-slot:item.percentage="{ item }">
                            <div>
                                {{ item.percentage }}%
                            </div>
                        </template>
                        <template v-slot:item.price="{ }">
                            <div>
                                {{ currencyFormatter.format(exam['price']
            || "$0") }}
                            </div>
                        </template>
                        <template v-slot:item.utility="{ item }">
                            <div>
                                {{ currencyFormatter.format(
            (exam['price'] || 0)
            * (item.percentage / 100)) }}
                            </div>
                        </template>
                        <template v-slot:item.minimum="{ item }">
                            <div>
                                {{ currencyFormatter.format(
            (exam['price'] || 0)
            * item.min_inscriptions * (item.percentage / 100)) }}
                            </div>
                        </template>
                        <template v-slot:item.maximum="{ item }">
                            <div>
                                {{ currencyFormatter.format(
            (exam['price'] || 0)
            * item.max_inscriptions * (item.percentage / 100)) }}
                            </div>
                        </template>
                        <template v-slot:item.annual="{ item }">
                            <div>
                                {{ currencyFormatter.format(
            (exam['price'] || 0)
            * item.max_inscriptions * (item.percentage / 100) * 6) }}
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <span>
            {{ exam && exam['count'] ? exam['count'] : 0 }}/{{ exam ? exam['goal'] : 0 }}
            | Requeridos {{ exam ? exam['required'] : 0 }}
        </span>
    </div>
</template>

<script>
import ENV from '../../../env.js'
export default {
    mounted() { },
    data() {
        return {
            active: false,
            bimesterStart: null,
            renderDoyang: {},
        };
    },
    props: {
        doyang: {
            type: Object,
            required: true
        },
        examName: {
            type: String,
            required: true
        },
        areGoalsModifiable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        establishGoal(doyangId, examName, type) {
            const goal = prompt('Introduce la nueva meta')
            this.$store.dispatch('Doyang/stablish_goal', {
                doyang_id: doyangId,
                exam_name: examName,
                exam_id: this.exam.id,
                type,
                goal
            }).then(() => {
                this.renderDoyang.next_exams_totals[examName]['goal'] = goal
            })
        },
    },
    watch: {
        doyang() {
            this.renderDoyang = JSON.parse(JSON.stringify(this.doyang)) || {}
        },
        active() {
            if (this.active) {
                this.$store.dispatch('Doyang/show', `${this.doyang.id}?with=doyang_surrogation,doyang_surrogation.doyang_surrogation_exams&append=exam_record`)
            }
            this.$store.dispatch('Configuration/show', 1).then((res) => {
                this.bimesterStart = res.value
            })
            this.renderDoyang = JSON.parse(JSON.stringify(this.doyang)) || {}
        }
    },
    computed: {
        canEditGoals() {
            return this.canSeeGeneralDirection || this.isWindowToEditGoals
        },
        canSeeGeneralDirection() {
            if (!this.authInfo) return false
            return ENV.permissions.can_see_general_direction.includes(parseInt(this?.authInfo?.user?.role_id))
        },
        isWindowToEditGoals() {
            const bimesterStart = new Date(this.bimesterStart)
            const today = new Date()
            const fifteenDaysAfterBimesterStart = new Date(bimesterStart.setDate(bimesterStart.getDate() + 15))
            return today < fifteenDaysAfterBimesterStart
        },
        authInfo() {
            return this.$store.getters['Auth/authInfo']
        },
        exam_record() {
            return this.$store.getters['Doyang/doyang']?.exam_record || {}
        },
        doyang_surrogation_exams() {
            return this.$store.getters['Doyang/doyang']?.doyang_surrogation?.doyang_surrogation_exams || []
        },
        exam() {
            return this.renderDoyang?.next_exams_totals?.[this.examName] || {}
        },
        currencyFormatter() {
            return new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
        },
    },
};
</script>

<style scoped>
h1 {
    color: blue;
}

p {
    font-size: 18px;
}
</style>