<template>
    <v-container>
        <v-progress-circular :size="200" :width="10" v-if="loading" color="green lighten-2" indeterminate />
        <LoginComponent v-if="!authInfo.user && !loading" />
        <v-row v-if="authInfo.user && !loading">
            <v-col>
                <v-row>
                    <v-col>
                        <strong>Proximos eventos</strong>
                        <br>
                        <ul>
                            <li v-for="event in events.sort((event1, event2) => {
            if (event1.has_passed && !event2.has_passed)
                return 1;
            else if (!event1.has_passed && event2.has_passed)
                return -1;
            if (event1.date > event2.date)
                return 1;
            else if (event1.date < event2.date)
                return -1;
            else
                return 0
        })" :key="event.id">
                                <del v-if="event.has_passed">{{ event.name }} ({{ event.parsed_date }})</del>
                                <p v-else>{{ event.name }} ({{ event.parsed_date }})</p>
                            </li>
                            <br>
                            <strong>Próximos torneos</strong>
                            <li v-for="tournament in tournaments.sort((event1, event2) => {
            if (event1.has_passed && !event2.has_passed)
                return 1;
            else if (!event1.has_passed && event2.has_passed)
                return -1;
            if (event1.date > event2.date)
                return 1;
            else if (event1.date < event2.date)
                return -1;
            else
                return 0
        })" :key="tournament.id">
                                <del v-if="tournament.has_passed">{{ tournament.name }} ({{ tournament.parsed_date
                                    }})</del>
                                <p v-else>{{ tournament.name }} ({{ tournament.parsed_date }})</p>
                            </li>
                        </ul>
                    </v-col>
                    <v-col v-if="nextExam">
                        <strong>Fecha de próximo examen</strong>
                        <br>
                        {{ nextExam.date }}
                        <br>
                        ({{ nextExam.sinodal }}, {{ nextExam.sinodalResidency }})
                        <br>
                        <a @click="() => { seeMoreExamsDialog = true }">Ver más</a>
                    </v-col>
                </v-row>
                <v-row v-if="authInfo.user.doyangs_as_responsible && authInfo.user.doyangs_as_responsible.length">
                    <v-col cols=12>
                        <h3>DOYANGS (COMO RESPONSABLE)</h3>
                    </v-col>
                    <v-col cols=12 lg=4
                        v-for="doyang in authInfo.user.doyangs_as_responsible.filter((doyang) => doyang.active)"
                        :key="doyang.id">
                        <DoyangCard :areGoalsModifiable="areGoalsModifiable" :doyang="doyang"></DoyangCard>
                    </v-col>
                </v-row>
                <v-row
                    v-if="(authInfo.user.doyangs_as_personal && authInfo.user.doyangs_as_personal.length) && !isGeneralDirector">
                    <v-col cols=12>
                        <h3>DOYANGS (COMO PERSONAL)</h3>
                    </v-col>
                    <v-col cols=12 lg=4
                        v-for="doyang in authInfo.user.doyangs_as_personal.filter(doyang => doyang.active)"
                        :key="doyang.id">
                        <DoyangCard :areGoalsModifiable="areGoalsModifiable" :doyang="doyang"></DoyangCard>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols=12>
                        <h3>TODOS LOS DOYANGS</h3>
                    </v-col>
                    <v-col offset=6 cols=12 lg=4>
                        <CapsVTextField placeholder="Buscar doyang" v-model="doyangSearch"></CapsVTextField>
                    </v-col>
                    <!--Tabs-->
                    <v-col cols=12>
                        <v-tabs v-model="allDoyangsClubsTab">
                            <v-tab>Doyangs ({{ prechargedDoyangs.length }})</v-tab>
                            <v-tab>Clubes ({{ prechargedClubs.length }})</v-tab>
                        </v-tabs>
                        <v-row>
                            <v-col cols=12 lg=4 v-for="doyang in prechargedDoyangsOrClubs" :key="doyang.id">
                                <ClickOnlyDoyangCard :areGoalsModifiable="areGoalsModifiable" :doyang="doyang">
                                </ClickOnlyDoyangCard>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols=4 lg=2 v-if="hasPermission('Tareas bimestrales,Doyang Tareas Bimestrales', 'read')">
                <router-link :to="{ name: 'MonthlyDutyIndex' }"><strong>Tareas Bimestrales</strong></router-link>
                <h5 class="text-left" v-if="isGeneralDirection">Dirección General</h5>
                <ul class="text-left" v-if="isGeneralDirection">
                    <ul>
                        <div class="d-flex mb-n10" v-for="monthlyDuty in monthlyDutiesNonCheckedDG"
                            :key="monthlyDuty.id">
                            <v-checkbox v-if="monthlyDuty.super_important" @click="monthlyDutyDone(monthlyDuty, true)"
                                class="my-checkbox-super-important"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`">
                            </v-checkbox>
                            <v-checkbox v-else @click="monthlyDutyDone(monthlyDuty, true)" class="my-checkbox"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                        </div>
                    </ul>
                </ul>
                <h5 class="mt-3 text-left">Doyang</h5>
                <ul class="text-left">
                    <ul>
                        <div class="d-flex mb-n10" v-for="monthlyDuty in monthlyDutiesNonChecked" :key="monthlyDuty.id">
                            <v-checkbox v-if="monthlyDuty.super_important" @click="monthlyDutyDone(monthlyDuty, true)"
                                class="my-checkbox-super-important"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                            <v-checkbox v-else @click="monthlyDutyDone(monthlyDuty, true)" class="my-checkbox"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                        </div>
                    </ul>
                </ul>
                <h5 class="mt-5 text-left">Hechas</h5>
                <ul class="text-left" style="list-style-type: none;">
                    <ul>
                        <div class="d-flex mb-n10" v-for="monthlyDuty in monthlyDutiesChecked" :key="monthlyDuty.id">
                            <v-checkbox v-if="monthlyDuty.super_important" :disabled="true" :value="true"
                                class="my-checkbox-super-important"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                            <v-checkbox v-else :disabled="true" :value="true" class="my-checkbox"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                        </div>
                    </ul>
                </ul>
                <h5 class="mt-5 text-left">Mis tareas</h5>
                <ul class="text-left" style="list-style-type: none;">
                    <ul>
                        <div class="d-flex mb-n10" v-for="monthlyDuty in selectedDoyangmonthlyDutiesNonChecked"
                            :key="monthlyDuty.id">
                            <v-checkbox v-if="monthlyDuty.super_important" @click="monthlyDutyDone(monthlyDuty, true)"
                                class="my-checkbox-super-important"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                            <v-checkbox v-else @click="monthlyDutyDone(monthlyDuty, true)" class="my-checkbox"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                        </div>
                    </ul>
                </ul>
                <h5 class="mt-5 text-left">Mis tareas hechas</h5>
                <ul class="text-left" style="list-style-type: none;">
                    <ul>
                        <div class="d-flex mb-n10" v-for="monthlyDuty in selectedDoyangMonthlyDutiesChecked"
                            :key="monthlyDuty.id">
                            <v-checkbox v-if="monthlyDuty.super_important" :disabled="true" :value="true"
                                class="my-checkbox-super-important"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                            <v-checkbox v-else :disabled="true" :value="true" class="my-checkbox"
                                :label="`${monthlyDuty.name} (${monthlyDuty.parsed_due_date})`"></v-checkbox>
                        </div>
                    </ul>
                </ul>
            </v-col>
        </v-row>
        <v-dialog v-if="authInfo.user && !loading" v-model="seeMoreExamsDialog" width="1500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    EXAMENES DE ESTE AÑO
                </v-card-title>
                <v-card-text>
                    <v-tabs v-model="thisYearExamsTab">
                        <v-tab>Este examen</v-tab>
                        <v-tab>Próximos examenes</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="thisYearExamsTab">
                        <v-tab-item>
                            <h3>CRONOGRAMA</h3>
                            <v-col v-if="nextExam && nextExam.blocks">
                                <ol v-for="block in nextExam.blocks" :key="block.id">
                                    <li>({{ `${block.start_time} - ${block.end_time}` }}): {{ block.name }}</li>
                                </ol>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <ul>
                                <li :class="exam.date < new Date().toISOString() ? 'crossed' : ''"
                                    v-for="exam in thisYearExams" :key="exam.id">
                                    {{ exam.name }} ({{ exam.parsed_date }}) - {{ exam.venue.name }}, {{
            exam.venue.city.name }}
                                </li>
                            </ul>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray lighten-2" @click="seeMoreExamsDialog = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<style scoped>
.v-progress-circular {
    margin-top: 20%;
}

.v-card__text,
.v-card__title {
    word-break: normal;
}

.li--bimestral_duties {
    font-size: 0.7rem;
}

.li--bimestral_duties--crossed {
    font-size: 0.7rem;
    color: green;
    text-decoration: line-through;
}

.crossed {
    text-decoration: line-through;
}

::v-deep .my-checkbox .v-label {
    font-size: 10px;
}

::v-deep .my-checkbox-super-important .v-label {
    font-size: 10px;
    font-weight: bolder;
    color: red;
}
</style>
<script>
// @ is an alias to /src
import LoginComponent from '../components/login/Login.vue'
import DoyangCard from '../components/doyangs/DoyangCard.vue'
import ClickOnlyDoyangCard from '../components/doyangs/ClickOnlyDoyangCard.vue'
import ENV from '../../env.js'
export default {
    name: 'Home',
    data() {
        return {
            daysToModify: null,
            allDoyangsClubsTab: null,
            minAcceptableExamEfficiency: null,
            thisYearExamsTab: null,
            downloadingTrafficLight: false,
            disableDoyangSeeMore: false,
            selectedDoyangMonthlyDuties: [],
            currencyFormatter: new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }),
            seeMoreExamsDialog: false,
            activeDetailsDoyang: null,
            detailsTab: null,
            showDoyangDetailsModal: false,
            showModalRiskStudents: false,
            listRiskStudents: [],
            bimesterStart: null,
            bimesterEnd: null,
            loading: true,
            orderDoyangsStudentNumber: false,
            orderDoyangsProfesor: false,
            doyangSearch: "",
            ENV,
            studentsByDegree: {}
        }
    },
    mounted() {
        this.$store.dispatch('Time/time')
        this.$store.dispatch('Auth/me')
        if (!this?.authInfo?.user) {
            this.loading = false
        }
        this.$store.dispatch('Exam/index', '?append=parsed_date&with=sinodal,blocks')
        this.$store.dispatch('Event/index')
        this.$store.dispatch('Tournament/index')
        this.$store.dispatch('MonthlyDuty/index', '?append=doyangs_done').then(() => this.loading = false)
        this.$store.dispatch('Student/count', '&active=1')
        this.$store.dispatch('Configuration/show', 27).then((res) => {
            this.minAcceptableExamEfficiency = res.value
        })
        this.$store.dispatch('Configuration/show', 1).then((res) => {
            this.bimesterStart = res.value
        })
        this.$store.dispatch('Configuration/show', 2).then((res) => {
            this.bimesterEnd = res.value
        })
        this.$store.dispatch('Configuration/show', 6).then((res) => {
            this.daysToModify = res.value
        })
        if (this.canSeeGeneralDirection) {
            this.$store.dispatch('Doyang/index', 'front_page=1')
        } else {
            if (this.selectedDoyang)
                this.selectDoyang(this.selectedDoyang)
            this.$store.dispatch('MonthlyDuty/doyangMonthlyDuties')
        }
    },
    computed: {
        serverTime() {
            if (!this.$store.getters['Time/time']) {
                return new Date().toISOString()
            }
            return this.$store.getters['Time/time'].split(' ').join('T')
        },
        areGoalsModifiable() {
            const bimesterStart = new Date(this.bimesterStart)
            const serverTimeDate = new Date(this.serverTime)

            const fifteenDaysAfterBimesterStart = new Date(bimesterStart.setDate(bimesterStart.getDate() + 15))

            return serverTimeDate < fifteenDaysAfterBimesterStart
        },
        prechargedDoyangs() {
            return this.$store.getters['Doyang/doyangs'].filter(doyang => {
                return doyang.doyang_type_id == 1
            })
        },
        prechargedClubs() {
            return this.$store.getters['Doyang/doyangs'].filter(doyang => {
                return doyang.doyang_type_id == 2
            })
        },
        prechargedDoyangsOrClubs() {
            if (this.allDoyangsClubsTab === 0) {
                return this.prechargedDoyangs.filter(doyang => {
                    return doyang.name.toLowerCase().includes(this.doyangSearch.toLowerCase())
                })
            } else {
                return this.prechargedClubs.filter(doyang => {
                    return doyang.name.toLowerCase().includes(this.doyangSearch.toLowerCase())
                })
            }
        },
        canEditGoals() {
            return this.canSeeGeneralDirection || this.isWindowToEditGoals
        },
        isWindowToEditGoals() {
            const bimesterStart = new Date(this.bimesterStart)
            const today = new Date()
            const fifteenDaysAfterBimesterStart = new Date(bimesterStart.setDate(bimesterStart.getDate() + 15))
            return today < fifteenDaysAfterBimesterStart
        },
        activeDetailsDoyangTotalExams() {
            const examNames = Object.keys(this.activeDetailsDoyang.next_exams_totals)
            return examNames.reduce((acc, examName) => {
                return (+acc) + (+(this.activeDetailsDoyang.next_exams_totals[examName]['count'] ?? 0))
            }, 0)
        },
        nextExam() {
            if (!this.exams) return null
            const allExams = this.exams
            let sortedActiveExams = allExams.sort((examA, examB) => {
                if (examA.date > examB.date) return 1
                if (examA.date < examB.date) return -1
                if (examA.date == examB.date) return 0
            })
            sortedActiveExams = sortedActiveExams.filter(exam => {
                return exam.date >= new Date().toISOString()
            })
            const nextExamObject = sortedActiveExams?.[0];
            if (!nextExamObject) return null
            const examStartTimeString = nextExamObject.begins_at;
            const examStartTime = new Date(`01/01/2007 ${examStartTimeString}`);
            let accumulativeTime = null;
            if (nextExamObject.blocks) {
                nextExamObject.blocks = nextExamObject.blocks.sort((a, b) => {
                    return a.pivot.order - b.pivot.order
                })
            }
            nextExamObject.blocks = nextExamObject?.blocks.map((block) => {
                const blockDurationMin = block.duration_min
                const numberOfBlocks = Math.ceil((block.pivot.actual_quantity || 0) / (block.pivot.max_quantity || 1));
                const blockStartTime = accumulativeTime || new Date(examStartTime.getTime() + (block.pivot.order * (blockDurationMin * 60000)));
                const blockEndTime = new Date(blockStartTime.getTime() + (block.duration_min * 60000 * numberOfBlocks));
                accumulativeTime = blockEndTime;
                block['start_time'] = blockStartTime.toLocaleTimeString();
                block['end_time'] = blockEndTime.toLocaleTimeString();
                return block;
            })

            return {
                date: nextExamObject?.parsed_date || 'No hay examen siguiente',
                sinodal: nextExamObject?.sinodal?.full_name_honorific || '',
                sinodalResidency: nextExamObject?.sinodal?.address || '',
                blocks: nextExamObject?.blocks || []
            }
        },
        exams() {
            return this.$store.getters['Exam/exams']
        },
        thisYearExams() {
            let toReturn = this.$store.getters['Exam/exams'].filter(exam => {
                const thisYear = new Date().toISOString().substr(0, 4);
                return exam.date > `${thisYear}-01-01` && exam.date < `${thisYear}-12-31`
            })
            toReturn = toReturn.sort((exam1, exam2) => {
                if (exam1.date > exam2.date) return 1
                if (exam1.date == exam2.date) return 0
                return -1
            })
            return toReturn
        },
        events() {
            let events = this.$store.getters['Event/events'].filter((event) => {
                // parse date from bimester end, add 5 days and get it back to yyyy-mm-dd
                const bimesterEndDate = new Date(this.bimesterEnd)
                const fiveDaysAfterBimesterEnd = new Date(bimesterEndDate.setDate(bimesterEndDate.getDate() + 5))
                const fiveDaysAfterBimesterEndString = fiveDaysAfterBimesterEnd.toISOString().split('T')[0]
                if (event.date >= this.bimesterStart && event.date <= fiveDaysAfterBimesterEndString) return true
                return false
            })
            events = events.map(event => {
                event.has_passed = event.date < new Date().toISOString()
                return event
            })
            return events
        },
        tournaments() {
            const bimesterEndDate = new Date(this.bimesterEnd)
            const fiveDaysAfterBimesterEnd = new Date(bimesterEndDate.setDate(bimesterEndDate.getDate() + 5))
            const fiveDaysAfterBimesterEndString = fiveDaysAfterBimesterEnd.toISOString().split('T')[0]
            if (!this.$store?.getters['Tournament/tournaments']?.filter) return [];
            let events = this.$store.getters['Tournament/tournaments'].filter((event) => {
                if (event.date >= this.bimesterStart && event.date <= fiveDaysAfterBimesterEndString) return true
                return false
            })
            events = events.map(event => {
                event.has_passed = event.date < new Date().toISOString()
                return event
            })
            return events
        },
        canSeeGeneralDirection() {
            if (!this.authInfo) return false
            return ENV.permissions.can_see_general_direction.includes(parseInt(this?.authInfo?.user?.role_id))
        },
        currentMonth() {
            const d = new Date()
            let month = d.getMonth()
            return ENV.months[month + 1]
        },
        totalStudents() {
            return this.$store.getters['Student/count']
        },
        authInfo() {
            return this.$store.getters['Auth/authInfo']
        },
        isGeneralDirection() {
            return this.ENV.DIRECCIÓN_GENERAL_ID == this.selectedDoyang?.id
        },
        isGeneralDirector() {
            return 1 == this.authInfo.user.id
        },
        monthlyDuties() {
            const monthlyDuties = this.$store.getters['MonthlyDuty/monthlyDutys'].filter((duty) => {
                return (this.isGeneralDirection == duty.to_general_direction) || (!this.isGeneralDirection == duty.to_doyang)
            })
            return monthlyDuties
        },
        monthlyDutiesChecked() {
            if (!this.selectedDoyang) return []
            return this.monthlyDuties.filter((duty) => {
                return (duty.doyangs_done?.includes(parseInt(this.selectedDoyang.id)) ||
                    duty.doyangs_done?.includes(parseInt(this.selectedDoyang.id).toString())) &&
                    !(duty.doyang_id)
            })
        },
        monthlyDutiesNonChecked() {
            if (!this.selectedDoyang) return []
            let md = this.monthlyDuties?.filter((duty) => {
                return (!duty.doyangs_done?.includes(this.selectedDoyang.id) &&
                    !duty.doyangs_done?.includes(parseInt(this.selectedDoyang.id).toString())) &&
                    !(duty.doyang_id)
            })
            md = md.filter(duty => Number.parseInt(duty.to_doyang) == 1)
            return md.sort((duty, duty2) => {
                if (duty.super_important && !duty2.super_important) {
                    return -1; // duty comes first
                } else if (!duty.super_important && duty2.super_important) {
                    return 1; // duty2 comes first
                }
                if (duty.due_date > duty2.due_date) {
                    return 1
                } else if (duty.due_date < duty2.due_date) {
                    return -1
                } else {
                    return 0
                }
            })
        },
        monthlyDutiesNonCheckedDG() {
            if (!this.selectedDoyang) return []
            let md = this.monthlyDuties?.filter((duty) => {
                return (!duty.doyangs_done?.includes(this.selectedDoyang.id) &&
                    !duty.doyangs_done?.includes(parseInt(this.selectedDoyang.id).toString())) &&
                    !(duty.doyang_id)
            })
            md = md.filter(duty => Number.parseInt(duty.to_general_direction) == 1)
            return md.sort((duty, duty2) => {
                if (duty.super_important && !duty2.super_important) {
                    return -1; // duty comes first
                } else if (!duty.super_important && duty2.super_important) {
                    return 1; // duty2 comes first
                }
                if (duty.due_date > duty2.due_date) {
                    return 1
                } else if (duty.due_date < duty2.due_date) {
                    return -1
                } else {
                    return 0
                }
            })
        },
        selectedDoyangMonthlyDutiesChecked() {
            if (!this.selectedDoyang) return []
            const result = this.selectedDoyangMonthlyDuties.filter((duty) => {
                return duty.doyangs_done?.includes(parseInt(this.selectedDoyang.id)) ||
                    duty.doyangs_done?.includes(parseInt(this.selectedDoyang.id).toString())
            })
            return result
        },
        selectedDoyangmonthlyDutiesNonChecked() {
            if (!this.selectedDoyang) return []
            return this.selectedDoyangMonthlyDuties?.filter((duty) => {
                return !duty.doyangs_done?.includes(this.selectedDoyang.id) &&
                    !duty.doyangs_done?.includes(parseInt(this.selectedDoyang.id).toString())
            })
        },
        selectedDoyang() {
            return this.$store.getters['Auth/selectedDoyang']
        },
    },
    methods: {
        downloadDailyInscriptions(doyangId) {
            this.$store.dispatch('Doyang/show', `/${doyangId}/daily_inscriptions_csv`).then((res) => {
                this.downloadStringInNewPage(res, 'inscriptions.csv')
            })
        },
        downloadBimesterCsv(doyangId) {
            this.$store.dispatch('Doyang/show', `/${doyangId}/bimester_inscriptions_csv`).then((res) => {
                this.downloadStringInNewPage(res, 'inscriptions.csv')
            })
        },
        downloadBimesterDisenrollsCsv(doyangId) {
            this.$store.dispatch('Doyang/show', `${doyangId}/bimester_disenrolls_csv`).then((res) => {
                this.downloadStringInNewPage(res, 'inscriptions.csv')
            })
        },
        downloadTournamentListCsv(eventId, doyangId) {
            this.$store.dispatch('Tournament/index', `/${eventId}/payment_list?doyang_id=${doyangId}`).then((res) => {
                this.downloadStringInNewPage(res, 'events.csv')
            })
        },
        downloadTournamentListExcel(eventId, doyangId) {
            this.$store.dispatch('Tournament/downloadFile', `/${eventId}/payment_list_excel?doyang_id=${doyangId}`).then((res) => {
                this.downloadExcel(res, 'tournament_excel_' + eventId + '.xlsx')
            })
        },
        downloadTournamentHotelBusListExcel(eventId, doyangId) {
            this.$store.dispatch('Tournament/downloadFile', `/${eventId}/payment_bus_hotel_list_excel?doyang_id=${doyangId}`).then((res) => {
                this.downloadExcel(res, 'tournament_excel_' + eventId + '.xlsx')
            })
        },
        downloadEventHotelBusListExcel(eventId, doyangId) {
            this.$store.dispatch('Event/downloadFile', `/${eventId}/payment_bus_hotel_list_excel?doyang_id=${doyangId}`).then((res) => {
                this.downloadExcel(res, 'events_excel_' + eventId + '.xlsx')
            })
        },
        downloadTrafficLight(doyangId, recalculate = false) {
            if (recalculate) {
                if (!confirm('¿Estás seguro que quieres recalcular el semáforo, esto tomará algunos minutos?')) return;
            }
            const examsTotalsDict = this.activeDetailsDoyang?.next_exams_totals;
            const examsTotalsValues = Object.values(examsTotalsDict);
            const nextKupsExam = examsTotalsValues?.find(element => !element?.is_black_belt)
            if (!nextKupsExam) {
                alert('No hay examen siguiente');
                return;
            }
            this.downloadingTrafficLight = true;
            const recalculateString = recalculate ? '&recalculate=true&' : '';
            this.$store.dispatch('Doyang/downloadFile', `/${doyangId}/traffic_light?${recalculateString}exam_id=${nextKupsExam.id}`).then((res) => {
                this.downloadExcel(res, 'traffic_light_' + doyangId + '.xlsx')
                this.downloadingTrafficLight = false;
            }).catch(() => {
                this.downloadingTrafficLight = false;
            })
        },
        downloadExcel(response, fileName) {
            try {
                const blob = new Blob([response], { type: 'application/octet-stream' });
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        },

        downloadEventListCsv(eventId, doyangId) {
            this.$store.dispatch('Event/index', `/${eventId}/payment_list?doyang_id=${doyangId}`).then((res) => {
                this.downloadStringInNewPage(res, 'events.csv')
            })
        },
        downlaodMeetingCsv(eventId, doyangId) {
            this.$store.dispatch('Event/index', `/${eventId}/assistance?doyang_id=${doyangId}`).then((res) => {
                this.downloadStringInNewPage(res, 'assistance_event.csv')
            })
        },
        hasPermission(name, action = false) {
            const names = name.split(",")
            if (names.length > 1) {
                let hasPermission = false
                names.forEach(name => {
                    if (hasPermission) return;
                    hasPermission = this.hasPermission(name, action)
                })
                return hasPermission
            }
            if (!name) return false;
            const permissions = this.authInfo?.user?.permissions
            if (!permissions) return false
            const singlePermissionkey = Object.keys(permissions).filter(key => {
                return permissions[key].name === name
            })?.[0]
            const singlePermission = permissions?.[singlePermissionkey]
            if (!singlePermissionkey) return false
            if (!action && singlePermission) {
                return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
            }
            return singlePermission?.[action] == 1 || false
        },
        getCurrentDoyangExamsComission(count, examName) {
            if (!this?.activeDetailsDoyang?.doyang_surrogation?.doyang_surrogation_exams) return this.currencyFormatter.format(0);
            const percentage = this.activeDetailsDoyang.doyang_surrogation.doyang_surrogation_exams.filter((surrogation) => {
                return (count >= surrogation['min_inscriptions']) &&
                    (count <= surrogation['max_inscriptions'])
            })?.[0]?.percentage || 0
            return this.currencyFormatter.format((percentage / 100) * (count || 0) * this.activeDetailsDoyang.next_exams_totals[examName]['price'])
        },
        getCurrentDoyangTournamentsComission(count, tournamentName) {
            if (!this?.activeDetailsDoyang?.doyang_surrogation?.doyang_surrogation_tournaments) return this.currencyFormatter.format(0);
            const percentage = this.activeDetailsDoyang.doyang_surrogation.doyang_surrogation_tournaments.filter((surrogation) => {
                return (count >= surrogation['min_inscriptions']) &&
                    (count <= surrogation['max_inscriptions'])
            })?.[0]?.percentage || 0
            return this.currencyFormatter.format((percentage / 100) * (count || 0) * this.activeDetailsDoyang.next_tournaments_totals[tournamentName]['price'])
        },
        getCurrentDoyangEventsComission(count, eventName) {
            if (!this?.activeDetailsDoyang?.doyang_surrogation?.doyang_surrogation_events) return this.currencyFormatter.format(0);
            const percentage = this.activeDetailsDoyang.doyang_surrogation.doyang_surrogation_events.filter((surrogation) => {
                return (count >= surrogation['min_inscriptions']) &&
                    (count <= surrogation['max_inscriptions'])
            })?.[0]?.percentage || 0
            return this.currencyFormatter.format((percentage / 100) * (count || 0) * this.activeDetailsDoyang.next_events_totals[eventName]['price'])
        },
        downloadBlackBelts() {
            this.$store.dispatch("Doyang/show", `${this.activeDetailsDoyang.id}/black_belt_prospects_csv`).then((response) => {
                this.downloadStringInNewPage(response, 'black_belts.csv')
            })
        },
        downloadRiskStudents() {
            this.$store.dispatch("Doyang/show", `${this.activeDetailsDoyang.id}/risk_students`).then((response) => {
                this.downloadStringInNewPage(response, 'alumnos_en_riesgo.csv')
            })
        },
        downloadStringInNewPage(string, filename) {
            const blob = new Blob([string], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        establishGoal(doyangId, examName, type) {
            const goal = prompt('Introduce la nueva meta')
            this.$store.dispatch('Doyang/stablish_goal', {
                doyang_id: doyangId,
                event_name: examName,
                type,
                goal
            })
        },
        doyangDetails(doyang) {
            doyang.month_birthdays = doyang.month_birthdays.sort(this.sortBirthdays)
            this.activeDetailsDoyang = doyang
            this.disableDoyangSeeMore = true

            const examsTotalsDict = this.activeDetailsDoyang?.next_exams_totals;
            const examsTotalsValues = Object.values(examsTotalsDict);
            const nextKupsExam = examsTotalsValues?.find(element => !element?.is_black_belt)
            this.$store.dispatch('Doyang/show', `${doyang.id}?append=exam_efficiency,traffic_light_report_array,bimester_disenrollments,daily_inscriptions,bimester_inscriptions,meeting_record,next_meetings_totals,next_exams_totals,next_events_totals,next_tournaments_totals,pending_shipments,risk_students,black_belt_prospects_count,inscription_record,exam_record,event_record,tournament_record&with=doyang_surrogation,doyang_surrogation.doyang_surrogation_exams,doyang_surrogation.doyang_surrogation_events,doyang_surrogation.doyang_surrogation_tournaments${nextKupsExam ? '&exam_id=' + nextKupsExam.id : ''}`).then((res) => {
                this.disableDoyangSeeMore = false
                this.activeDetailsDoyang.black_belt_prospects_count = res.black_belt_prospects_count
                this.activeDetailsDoyang.inscription_record = res.inscription_record
                this.activeDetailsDoyang.doyang_surrogation = res.doyang_surrogation
                this.activeDetailsDoyang.tournament_record = res.tournament_record
                this.activeDetailsDoyang.exam_record = res.exam_record
                this.activeDetailsDoyang.event_record = res.event_record
                this.activeDetailsDoyang.risk_students = res.risk_students
                this.activeDetailsDoyang.pending_shipments = res.pending_shipments
                this.activeDetailsDoyang.next_meetings_totals = res.next_meetings_totals
                this.activeDetailsDoyang.meeting_record = res.meeting_record
                this.activeDetailsDoyang.daily_inscriptions = res.daily_inscriptions
                this.activeDetailsDoyang.bimester_inscriptions = res.bimester_inscriptions
                this.activeDetailsDoyang.bimester_disenrollments = res.bimester_disenrollments
                this.activeDetailsDoyang.exam_efficiency = res.exam_efficiency
                this.activeDetailsDoyang.green_users = 0;
                this.activeDetailsDoyang.yellow_users = 0;
                this.activeDetailsDoyang.red_users = 0;
                this.activeDetailsDoyang.black_users = 0;
                this.activeDetailsDoyang.next_exams_totals = res.next_exams_totals
                this.activeDetailsDoyang.next_tournaments_totals = res.next_tournaments_totals
                this.activeDetailsDoyang.next_events_totals = res.next_events_totals
                res?.traffic_light_report_array?.forEach((user) => {
                    this.activeDetailsDoyang.green_users += user.stoplight == 'verde' || 0;
                    this.activeDetailsDoyang.yellow_users += user.stoplight == 'amarillo' || 0;
                    this.activeDetailsDoyang.red_users += user.stoplight == 'rojo' || 0;
                    this.activeDetailsDoyang.black_users += user.stoplight == 'negro' || 0;
                })
                this.showDoyangDetailsModal = true;
            }).catch(() => {
                this.disableDoyangSeeMore = false;
                // alert('Hubo un error obteniendo los datos del Doyang');
            });
        },
        sortBirthdays(a, b) {
            return a?.birth_date.substring(5) > b?.birth_date.substring(5) ? 1 : -1
        },
        parseDegreeBelts(doyang) {
            let studentsByDegree = {}
            studentsByDegree['11vo a 10mo kup'] = doyang.totals_by_belt['BLANCA 11° KUP']
            studentsByDegree['10mo a 9no kup'] = doyang.totals_by_belt['BLANCA 10° KUP']
            studentsByDegree['Amarillas'] = doyang.totals_by_belt['AMARILLA 8° KUP'] + doyang.totals_by_belt['AMARILLA 9° KUP']
            studentsByDegree['Verdes'] = doyang.totals_by_belt['VERDE 6° KUP'] + doyang.totals_by_belt['VERDE 7° KUP']
            studentsByDegree['Azules'] = doyang.totals_by_belt['AZUL 4° KUP'] + doyang.totals_by_belt['AZUL 5° KUP']
            studentsByDegree['Marrones'] = doyang.totals_by_belt['MARRÓN 2° KUP'] + doyang.totals_by_belt['MARRÓN 3° KUP']
            studentsByDegree['Rojas'] = doyang.totals_by_belt['ROJA 1° KUP'] + doyang.totals_by_belt['ROJA IEBY']
            studentsByDegree['Negras'] = doyang.totals_by_belt['CN 1°'] + doyang.totals_by_belt['CN 2°'] + doyang.totals_by_belt['CN 3°'] + doyang.totals_by_belt['CN 4°'] + doyang.totals_by_belt['CN 5°'] + doyang.totals_by_belt['CN 6°'] + doyang.totals_by_belt['CN 7°'] + doyang.totals_by_belt['CN 8°'] + doyang.totals_by_belt['CN 9°']
            return studentsByDegree
        },
        monthlyDutyDone(monthlyDuty, done) {
            const doyang_id = this.selectedDoyang.id
            const monthly_duty_id = monthlyDuty.id
            this.$store.dispatch('MonthlyDuty/update', {
                id: monthly_duty_id,
                monthly_duty_id,
                doyang_id: monthlyDuty.doyang_id || doyang_id,
                done,
            }).then((res) => {
                const mapped = this.selectedDoyangMonthlyDuties?.map((duty) => {
                    if (duty.id == res.id) {
                        const index = duty.doyangs_done.indexOf(doyang_id)
                        if (index !== -1)
                            duty.doyangs_done.splice(index, 1)
                        else
                            duty.doyangs_done.push(doyang_id)
                    }
                    return duty
                })
                this.$set(this, 'selectedDoyangMonthlyDuties', mapped)
                return ('finished')
            })

        },
    },
    components: {
        LoginComponent,
        DoyangCard,
        ClickOnlyDoyangCard,
    }
}
</script>
